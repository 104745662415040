import React from 'react';

interface TabMapping {
  SEIZURE: number;
  MORTGAGE: number;
  TRADING: number;
  TUNING: number;
}

interface HistoryCountViewProps {
  productData: ProductDetailResponse;
  onChange?: (newValue: number) => void | boolean;
  tabMapping: TabMapping;
}

const HistoryCountView = ({ productData, onChange, tabMapping }: HistoryCountViewProps) => {
  const handleClickDetail = (key: string, tabNum: number) => {
    if (onChange) {
      onChange(tabNum);
    }

    setTimeout(() => {
      const element = document.getElementById(key);
      const headerHeight = 60;

      if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - headerHeight;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }, 10);
  };

  const renderHistoryItem = (key: string, tabKey: keyof TabMapping, count: number | null, label: string) => (
    <div onClick={() => handleClickDetail(key, tabMapping[tabKey])}>
      <div className="flex items-center justify-center bg-gray-1 rounded-full w-16 h-16 sm280:w-[54px] sm280:h-[54px] text-center mb-[10px] cursor-pointer">
        {count !== null && (
          <span className={`text-${count > 0 ? 'primary' : 'gray-6'} text-[20px] font-bold`}>
            {count > 0 ? `${count}건` : <span className="text-gray-6 text-[18px] font-medium">없음</span>}
          </span>
        )}
      </div>
      <span className="text-gray-7 text-[14px] sm280:text-[12px]">{label}</span>
    </div>
  );

  return (
    <div className="border-t-8 border-gray-1">
      <div className="flex justify-center items-center text-center gap-[18px] sm280:gap-[10px] p-6">
        {productData.seizureHistory !== null &&
          productData.seizureCount !== null &&
          renderHistoryItem('SEIZURE', 'SEIZURE', productData.seizureCount, '압류이력')}
        {productData.mortgageHistory !== null &&
          productData.mortgageCount !== null &&
          renderHistoryItem('MORTGAGE', 'MORTGAGE', productData.mortgageCount, '저당이력')}
        {renderHistoryItem('TRADING', 'TRADING', productData.tradingHistoryCount, '소유자변경')}
        {renderHistoryItem('TUNING', 'TUNING', productData.tuningHistoryCount, '구조변경')}
      </div>
    </div>
  );
};

export default HistoryCountView;
