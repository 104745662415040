import { AxiosError } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';

import { createPurchaseAccompanyingServices } from '@/api/AdditionalServices/createAdditionalServices';
import apiManager from '@/api/AxiosInstance';
import { deleteInterestProducts, postInterestProducts, postProductInquiry, postProductInquiryCall } from '@/api/public';
import BasicButton from '@/components/Common/Button/BasicButton';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import ButtonPopup, { ButtonPopupProps } from '@/components/Common/Popup/ButtonPopup';
import ProductInquiryQr from '@/components/Common/Popup/Contents/ProductInquiryQr';
import DownToUpDetailPopup from '@/components/Common/Popup/DownToUpDetailPopup';
import BubbleComponent from '@/components/Common/Tooltip/BubbleComponent';
import { ChatOutlineIcon, PhoneRingOutlineIcon } from '@/components/Icon';
import AccompanyingGuidePopup from '@/components/More/PurchaseAccompanyingService/AccompanyingGuidePopup';
import { colors } from '@/const/colors';
import { PRODUCT_DETAIL } from '@/const/common';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { WOORI_WON_CAR_URL } from '@/const/url';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import useAuthRedirect from '@/hooks/useAuthRedirect';
import { phoneCall } from '@/utils/common';
import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import { Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

type ProductDetailProps = {
  product: ProductDetailResponse;
  isShowBubble: boolean;
};

interface PurchaseInquiryInfo {
  productId: number;
  name: string;
  phoneNumber: string;
  loaded: string;
}

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const ProductDetailFooter = ({ product, isShowBubble }: ProductDetailProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const authRedirect = useAuthRedirect();
  const { showToast } = useToastContext();
  const { member } = useMemberContext();
  const [interestProductId, setInterestProductId] = useState<number | null>();
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isMutating, setIsMutating] = useState(false);
  const [isOpenAccompanyingServiceModal, setIsOpenAccompanyingServiceModal] = useState(false);

  const inquiryForm = useRef<PurchaseInquiryInfo>();
  let inquiryFormData = inquiryForm.current;

  const [purchaseInquiryInfo, setPurchaseInquiryInfo] = useState<PurchaseInquiryInfo>({
    productId: product.id,
    name: '',
    phoneNumber: '',
    loaded: '',
  });
  const [buttonPopupInfo, setButtonPopupInfo] = useState<ButtonPopupProps>({
    isShow: false,
    onClose: () => {},
    buttons: [],
  });

  const [popupInfo, setPopupInfo] = useState<BasicPopupProps>({
    isShow: false,
    title: '',
    textContent: '',
    textRightBtn: '',
    onClickRightBtn: () => {},
    textLeftBtn: '',
    onClickLeftBtn: () => {},
  });

  const buttonRef = useRef<HTMLDivElement>(null);
  const [bubblePosition, setBubblePosition] = useState(0);

  useEffect(() => {
    if (buttonRef.current) {
      const buttonWidth = buttonRef.current.clientWidth;
      setBubblePosition(buttonWidth / 4);
    }
  }, []);

  useEffect(() => {
    if (product) {
      setInterestProductId(product?.interestedProductId || null);
    }
  }, [product]);

  useEffect(() => {
    if (member) {
      setPurchaseInquiryInfo({
        productId: product.id,
        name: member.name,
        phoneNumber: member.phoneNumber,
        loaded: product.loaded?.code || '',
      });
    }
  }, [member]);

  const startPhoneCall = () => {
    if (!authRedirect(PRODUCT_DETAIL(product.id))) {
      return;
    }

    postProductInquiryCallMutation.mutate(product.id);
    phoneCall(product.sellerSafetyNumber);
  };

  const startChatting = () => {
    if (!authRedirect(PRODUCT_DETAIL(product.id))) {
      return;
    }

    apiManager
      .post('/api/v1/chat-rooms', { productId: product.id })
      .then((response) => {
        if (response) {
          const responseData: ChattingDetailResponse = response.data;
          if (responseData.id) {
            navigate(`/chatting/room/${responseData.id}`);
          } else {
            navigate(`/chatting/room`, { state: { chatRoomData: responseData } });
          }
        }
      })
      .catch((error) => {
        showToast('문제가 생겼어요. 다시 시도해주세요', 'error', 'bottom');
      });
  };

  const buttons = [
    {
      icon: <PhoneRingOutlineIcon color={colors.gray[6]}></PhoneRingOutlineIcon>,
      label: '전화문의',
      description: ' * 판매자의 개인정보 보호를 위해 050 안심번호로 연결됩니다.',
      onClick: startPhoneCall,
    },
    {
      icon: <ChatOutlineIcon color={colors.gray[6]}></ChatOutlineIcon>,
      label: '채팅문의',
      onClick: startChatting,
    },
  ];

  const postInterestProductMutation = useMutation((productId: number) => postInterestProducts(productId), {
    onMutate: async () => {
      await queryClient.cancelQueries('productDetail');
      setIsMutating(true);
      setInterestProductId(0);
    },
    onSuccess: (response) => {
      if (response) {
        setInterestProductId(response.data);
      }
    },
    onError: () => {
      setInterestProductId(null);
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
    onSettled: () => {
      setIsMutating(false);
    },
  });

  const createInterestProduct = () => {
    if (!authRedirect(PRODUCT_DETAIL(product.id)) || isMutating) {
      return;
    }
    postInterestProductMutation.mutate(product.id);
  };

  const deleteInterestProductMutation = useMutation(
    (interestProductId: number) => deleteInterestProducts(interestProductId),
    {
      onMutate: async () => {
        await queryClient.cancelQueries('productDetail');
        setIsMutating(true);

        const prevData = interestProductId;

        setInterestProductId(null);
        return { prevData };
      },
      onError: (error, response, context) => {
        setInterestProductId(context?.prevData);
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
      onSettled: () => {
        setIsMutating(false);
      },
    },
  );

  const deleteInterestProduct = () => {
    if (isMutating) {
      return;
    }
    if (interestProductId) {
      deleteInterestProductMutation.mutate(interestProductId);
    }
  };

  const clickLikeButton = () => {
    if (!!interestProductId) {
      deleteInterestProduct();
    } else {
      createInterestProduct();
    }
  };

  const onCloseButtonPopup = () => {
    setButtonPopupInfo({ isShow: false, onClose: () => {}, buttons: [] });
  };

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    if (value) {
      setPurchaseInquiryInfo({ ...purchaseInquiryInfo, [name]: value });
    }
  }

  const isNotDirectProduct = product.salesType?.code === 'ASSURANCE' || product.salesType?.code === 'CONSIGNMENT';

  const onClickInquiry = () => {
    if (isMobile) {
      if (!authRedirect(PRODUCT_DETAIL(product.id))) {
        return;
      }

      setButtonPopupInfo({
        isShow: true,
        onClose: onCloseButtonPopup,
        title: product.truckName,
        buttons: buttons,
      });
    } else {
      setButtonPopupInfo({
        isShow: true,
        onClose: onCloseButtonPopup,
        title: '차량 구매문의',
        buttons: [],
        children: <ProductInquiryQr isNotDirectProduct={false} productId={product.id} />,
        minWidth: 520,
      });
    }
  };

  const updateInquiryInfo = (data: PurchaseInquiryInfo) => {
    inquiryFormData = data;
    setPurchaseInquiryInfo({ ...data });
  };

  const onClickPhoneCall = () => {
    if (!authRedirect(PRODUCT_DETAIL(product.id))) {
      return;
    }

    if (isMobile) {
      if (product.salesPeople) {
        phoneCall(product.salesPeople.phoneNumber);
      }
    } else {
      setButtonPopupInfo({
        isShow: true,
        onClose: onCloseButtonPopup,
        title: '차량 구매문의',
        minWidth: 520,
        buttons: [],
        children: (
          <ProductInquiryQr
            isNotDirectProduct
            productId={product.id}
            purchaseInquiryInfo={purchaseInquiryInfo}
            updateInquiryInfo={updateInquiryInfo}
            handleClickButton={onClickProductPurchaseInquiry}
          />
        ),
      });
    }
  };

  const postProductPurchasingInquiryMutation = useMutation(
    (request: ProductPurchasingInquiryRequest) => postProductInquiry(request),
    {
      onSuccess: (response) => {
        if (response) {
          onCloseButtonPopup();
          setIsShowPopup(true);
          setPopupInfo({
            isShow: true,
            title: '구매문의 등록 완료',
            textContent: '담당자가 확인 후</br>연락 드릴 예정입니다.',
            textRightBtn: '확인',
            onClickRightBtn: () => {
              setIsShowPopup(false);
            },
          });
        }
      },
      onError: (error: AxiosError) => {
        if (error.code === 'ALREADY_PRODUCT_INQUIRY_REQUESTED') {
          showToast(error.message, 'error', 'bottom');
        } else {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        }
      },
    },
  );

  const onClickProductPurchaseInquiry = () => {
    const request: ProductPurchasingInquiryRequest = {
      productId: inquiryFormData?.productId,
      name: inquiryFormData?.name || '',
      requestPhoneNumber: inquiryFormData?.phoneNumber || '',
      loaded: inquiryFormData?.loaded || '',
    };
    postProductPurchasingInquiryMutation.mutate(request);
  };

  const postProductInquiryCallMutation = useMutation((productId: number) => postProductInquiryCall(productId), {
    onSuccess: () => {},
    onError: () => {},
  });

  const handleClickPurchaseAccompanyingService = () => {
    setIsOpenAccompanyingServiceModal(true);
  };

  const handleClickApplyOk = () => {
    const requestData = {
      name: member?.name,
      phoneNumber: member?.phoneNumber,
      productId: product.id,
    };
    postPurchaseAccompanyingServices.mutate(requestData);
    setIsShowPopup(false);
  };

  const postPurchaseAccompanyingServices = useMutation(
    (request: PurchaseAccompanyingServicesForm) => createPurchaseAccompanyingServices(request),
    {
      onSuccess: () => {
        showToast('차량 구매 동행 서비스를 신청했어요.', 'success', 'bottom');
      },
      onError: (error: AxiosError) => {
        if (error.code && error.message) {
          showToast(error.message, 'error', 'bottom');
        } else {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        }
      },
      onSettled: () => {
        setIsOpenAccompanyingServiceModal(false);
      },
    },
  );

  const handleClickApply = () => {
    if (!authRedirect(PRODUCT_DETAIL(product.id))) {
      return;
    }

    setIsShowPopup(true);
    setPopupInfo({
      isShow: true,
      title: `${product.truckName}`,
      textContent: '해당 차량으로 차량 구매 동행<br/>서비스를 신청할까요?',
      textRightBtn: '신청하기',
      onClickRightBtn: () => {
        handleClickApplyOk();
      },
      textLeftBtn: '취소',
      onClickLeftBtn: () => {
        setIsShowPopup(false);
      },
    });
  };

  return (
    <>
      <div className="flex items-center justify-between bottom-0 left-0 w-full px-3 bg-white border-t shadow-[0_-2px_4px_rgba(0,0,0,0.05)] z-0">
        <div className="flex-none mr-4">
          <Checkbox
            {...label}
            checked={!!interestProductId || false}
            icon={<FavoriteBorder />}
            sx={{
              color: '#737373',
              '&.Mui-checked': {
                color: '#FF4D4F',
              },
              justifyContent: 'center',
            }}
            checkedIcon={<Favorite />}
            onClick={clickLikeButton}
          />
          <p className="justify-center text-gray-400 text-xs md:text-sm sm:text-xs text-center">찜하기</p>
        </div>
        <div className="flex flex-1 items-center my-2 text-base">
          <div className="flex w-full">
            {isNotDirectProduct && product.salesPeople && product.salesPeople.phoneNumber ? (
              <>
                <Link to={WOORI_WON_CAR_URL} className="flex w-full pr-2">
                  <Button variant="contained" className="w-full">
                    <span className="text-sm md:text-base sm:text-xs break-keep">캐피탈 조회하기</span>
                  </Button>
                </Link>
                <Button onClick={onClickPhoneCall} variant="contained" className="w-full">
                  <span className="text-sm md:text-base sm:text-xs break-keep">전화 문의하기</span>
                </Button>
              </>
            ) : (
              <div className="relative flex gap-2 w-full" ref={buttonRef}>
                <BasicButton
                  name="구매동행 서비스"
                  bgColor={colors.gray[0]}
                  borderColor={colors.gray[3]}
                  textColor={colors.gray[6]}
                  fontSize={16}
                  height={52}
                  fontWeight="bold"
                  onClick={() => handleClickPurchaseAccompanyingService()}
                ></BasicButton>

                <div
                  className={`${isShowBubble ? '' : 'hidden'} absolute top-[-50px] transform -translate-x-1/2 w-max`}
                  style={{
                    left: `calc(50% - ${bubblePosition}px)`,
                  }}
                >
                  <BubbleComponent
                    text="<strong class='text-gray-0 text-[14px]'>안전한 거래를 위해!</strong>"
                    bgColor={colors.gray[7]}
                    padding="6px 12px"
                    borderRadius="1.5em"
                  ></BubbleComponent>
                </div>
                <BasicButton
                  name="차주에게 연락하기"
                  bgColor={colors.primary}
                  borderColor={colors.primary}
                  textColor={colors.gray[0]}
                  fontSize={16}
                  height={52}
                  fontWeight="bold"
                  onClick={() => onClickInquiry()}
                ></BasicButton>
              </div>
            )}
          </div>
        </div>
      </div>
      <ButtonPopup
        isShow={buttonPopupInfo.isShow}
        onClose={buttonPopupInfo.onClose}
        title={buttonPopupInfo.title}
        buttons={buttonPopupInfo.buttons}
        children={buttonPopupInfo.children}
        minWidth={216}
      ></ButtonPopup>
      <BasicPopup
        isShow={isShowPopup}
        title={popupInfo.title}
        textContent={popupInfo.textContent}
        textRightBtn={popupInfo.textRightBtn}
        onClickRightBtn={popupInfo.onClickRightBtn}
        textLeftBtn={popupInfo.textLeftBtn}
        onClickLeftBtn={popupInfo.onClickLeftBtn}
        minWidth={240}
      ></BasicPopup>

      <DownToUpDetailPopup
        isShow={isOpenAccompanyingServiceModal}
        onClosePopup={() => setIsOpenAccompanyingServiceModal(false)}
        title={'차량 구매 동행 서비스'}
      >
        <div className="px-4 py-4">
          <AccompanyingGuidePopup></AccompanyingGuidePopup>
          <div className="py-4">
            <BasicButton
              name="서비스 신청하기"
              bgColor={colors.primary}
              borderColor={colors.primary}
              textColor={colors.gray[0]}
              fontSize={16}
              height={52}
              fontWeight="bold"
              onClick={() => handleClickApply()}
            ></BasicButton>
          </div>
        </div>
      </DownToUpDetailPopup>
    </>
  );
};

export default ProductDetailFooter;
