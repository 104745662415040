import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BEFORE_SALE, COMPLETED, ORIGIN_DATA_REGISTER, PAUSE, SALE } from '../../const/productStatus';
import DownToUpDetailPopup from '../Common/Popup/DownToUpDetailPopup';
import InputPopup from '../Common/Popup/InputPopup';
import apiManager from '@/api/AxiosInstance';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import { useToastContext } from '@/contexts/Common/ToastContext';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface MenuDataType {
  code: string;
  desc: string;
  onClick?: () => void;
}

export interface MoreMenuData {
  id: number;
  status: string;
}

export interface MoreMenuProps {
  id: number;
  status?: string;
  isShow: boolean;
  onToggleMenu: () => void;
  setProductList?: React.Dispatch<React.SetStateAction<ProductDetailResponse[]>>;
  setLicenseList?: React.Dispatch<React.SetStateAction<License[]>>;
  isLicense?: boolean;
  confirmTitle?: string;
  size?: 'small' | 'inherit' | 'large' | 'medium' | undefined;
  price?: number;
}

export default function ProductMoreMenu({
  id,
  status,
  isShow,
  onToggleMenu,
  setProductList,
  setLicenseList,
  isLicense = false,
  confirmTitle,
  size,
  price,
}: MoreMenuProps) {
  const navigate = useNavigate();
  const [actualSalePrice, setActualSalePrice] = useState<number>();
  const [menus, setMenus] = useState<MenuDataType[]>([]);
  const [isBasicPopUpShow, setIsBasicPopUpShow] = useState(false);
  const [path, setPath] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState(status || null);
  const { showToast } = useToastContext();
  const [isUsePriceInputPopup, setIsUsePriceInputPopup] = useState(false);
  const [popupProps, setPopupProps] = useState<BasicPopupProps>({
    isShow: false,
    title: '',
    textContent: '',
    componentContent: null,
    textLeftBtn: '',
    onClickLeftBtn: () => {},
    textRightBtn: '',
    onClickRightBtn: () => {},
  });

  useEffect(() => {
    if (isLicense) {
      setPath('license');
    } else {
      setPath('products');
    }
  }, []);

  const setMenuData = () => {
    switch (selectedStatus) {
      case ORIGIN_DATA_REGISTER:
      case BEFORE_SALE:
        setMenus([
          { code: 'MODIFY', desc: '수정하기' },
          { code: 'DELETE', desc: '삭제하기' },
        ]);
        break;
      case SALE:
        setMenus([
          { code: 'COMPLETED', desc: '판매완료' },
          { code: 'PAUSE', desc: '판매중지' },
          { code: 'MODIFY', desc: '수정하기' },
          { code: 'DELETE', desc: '삭제하기' },
        ]);
        break;
      case PAUSE:
        setMenus([
          { code: 'SALE', desc: '판매중' },
          { code: 'MODIFY', desc: '수정하기' },
          { code: 'DELETE', desc: '삭제하기' },
        ]);
        break;
      case COMPLETED:
        setMenus([{ code: 'DELETE', desc: '삭제하기' }]);
        break;
    }
    onToggleMenu();
  };

  const onClickMenu = () => {
    setMenuData();
  };

  const closeBasicPopUp = () => {
    setIsBasicPopUpShow(false);
  };

  const onClickDelete = () => {
    setPopupProps({
      ...popupProps,
      isShow: true,
      title: confirmTitle,
      textContent: `${isLicense ? '번호판을 정말 삭제하시겠어요?' : '차량을 정말 삭제하시겠어요?'}`,
      componentContent: null,
      textLeftBtn: '닫기',
      onClickLeftBtn: () => closeBasicPopUp(),
      textRightBtn: '삭제하기',
      onClickRightBtn: () => {
        onToggleMenu();
        deleteProduct();
      },
    });
  };

  const deleteProduct = () => {
    apiManager
      .delete(`/api/v1/${path}/${id}`)
      .then((response) => {
        if (!isLicense) {
          showToast('차량이 삭제되었어요.', 'success', 'bottom');
          if (setProductList) {
            setProductList((prev) => prev.filter((item) => item.id !== id));
          }
        } else if (setLicenseList) {
          setLicenseList((prev) => prev.filter((item) => item.id !== id));
          showToast('번호판이 삭제되었어요.', 'success', 'bottom');
        }
        closeBasicPopUp();
      })
      .catch((error) => {
        showToast('문제가 생겼어요. 다시 시도해주세요', 'error', 'bottom');
      });
  };

  const handleToastMsg = (selectedStatus: string) => {
    switch (selectedStatus) {
      case SALE:
        return '판매중으로 변경되었어요.';
      case PAUSE:
        return '판매중지로 변경되었어요.';
      case COMPLETED:
        return '판매완료로 변경되었어요.';
      default:
        return '';
    }
  };

  const onClickChangeStatusToCompleted = (selectedStatus: string) => {
    setPopupProps({
      ...popupProps,
      isShow: true,
      title: '',
      textContent: '판매 완료로 상태를</br>변경할까요?',
      componentContent: null,
      textLeftBtn: '닫기',
      onClickLeftBtn: () => closeBasicPopUp(),
      textRightBtn: '변경하기',
      onClickRightBtn: () => {
        onToggleMenu();
        onClickChangeStatus(selectedStatus);
      },
    });
  };

  const showActualSalePricePopup = () => {
    setIsBasicPopUpShow(false);
    setIsUsePriceInputPopup(true);
  };

  const onClickChangeStatus = (selectedStatus: string) => {
    if (path) {
      apiManager
        .patch(`/api/v1/${path}/${id}/status`, { status: selectedStatus })
        .then((response) => {
          if (!isLicense) {
            const responseData: ProductDetailResponse = response.data;
            if (setProductList) {
              setProductList((prevData) =>
                prevData.map((item) => {
                  if (item.id === responseData.id) {
                    return { ...item, status: responseData.status };
                  }
                  return item;
                }),
              );
            }
            if (responseData && responseData.status.code == COMPLETED) {
              setIsBasicPopUpShow(false);
              setIsUsePriceInputPopup(true);
              showActualSalePricePopup();
            }
          } else if (setLicenseList) {
            const responseData: License = response.data;
            setLicenseList((prevData) =>
              prevData.map((item) => {
                if (item.id === responseData.id) {
                  return { ...item, status: responseData.status };
                }
                return item;
              }),
            );
          }
          setSelectedStatus(selectedStatus);
          const newToastMsg = handleToastMsg(selectedStatus);
          if (!!newToastMsg) {
            showToast(newToastMsg, 'success', 'bottom');
          }
        })
        .catch((error) => {
          showToast('문제가 생겼어요. 다시 시도해주세요', 'error', 'bottom');
        });
    }
  };

  const onClickMenuItem = (code: string) => {
    onToggleMenu();
    switch (code) {
      case 'MODIFY':
        if (isLicense) {
          navigate(`/${path}/form/${id}`, { state: { isModify: true } });
        } else {
          navigate(`/${path}/sales/edit/${id}/#info`);
        }
        break;
      case 'DELETE':
        setIsBasicPopUpShow(!isBasicPopUpShow);
        onClickDelete();
        break;
      case 'COMPLETED':
        if (isLicense) {
          onClickChangeStatus(code);
        } else {
          setIsBasicPopUpShow(!isBasicPopUpShow);
          onClickChangeStatusToCompleted(code);
        }
        break;
      case 'PAUSE':
      case 'SALE':
        onClickChangeStatus(code);
        break;
    }
  };

  const modifyProductActualSalePrice = () => {
    apiManager
      .patch(`/api/v2/products/${id}`, { actualSalePrice: actualSalePrice })
      .then((response) => {
        const responseData: ProductDetailResponse = response.data;
        if (responseData) {
          showToast('실제 판매 가격 저장이 완료되었어요.', 'success', 'bottom');
          if (setProductList) {
            setProductList((prevData) =>
              prevData.map((item) => {
                if (item.id === responseData.id) {
                  return { ...item, actualSalePrice: responseData.actualSalePrice };
                }
                return item;
              }),
            );
          }
        }
      })
      .catch(() => showToast('문제가 생겼어요. 다시 시도해주세요', 'error', 'bottom'));
  };

  return (
    <>
      <button onClick={onClickMenu}>
        <MoreVertIcon fontSize={size || 'small'} htmlColor="#414141"></MoreVertIcon>
      </button>
      <BasicPopup
        isShow={isBasicPopUpShow}
        title={popupProps.title}
        textContent={popupProps.textContent}
        componentContent={popupProps.componentContent}
        textLeftBtn={popupProps.textLeftBtn}
        onClickLeftBtn={popupProps.onClickLeftBtn}
        textRightBtn={popupProps.textRightBtn}
        onClickRightBtn={popupProps.onClickRightBtn}
      ></BasicPopup>
      <InputPopup
        isShow={isUsePriceInputPopup}
        setIsShow={setIsUsePriceInputPopup}
        title="판매완료로 변경되었어요."
        content="실제 판매 금액을 입력해주세요."
        placeholder="판매 금액"
        price={price}
        id={id}
        setProductList={setProductList}
        onClickOk={modifyProductActualSalePrice}
        inputValue={actualSalePrice}
        setInputValue={setActualSalePrice}
      ></InputPopup>
      <DownToUpDetailPopup isShow={isShow} onClosePopup={onToggleMenu} title="메뉴">
        <div className="p-4">
          {menus?.map((item, index) => (
            <div
              key={index}
              className={`py-4 cursor-pointer ${item.code === 'DELETE' && 'text-red'}`}
              onClick={() => onClickMenuItem(item.code)}
            >
              {item.desc}
            </div>
          ))}
        </div>
      </DownToUpDetailPopup>
    </>
  );
}
