import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BasicButton from '../Common/Button/BasicButton';
import { colors } from '@/const/colors';
import '@/css/onBoadingStyle.css';
import Flicking, { EVENTS } from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';

type OnBoadingType = {
  onClickStart: () => void;
};

const DriveOnBoading = ({ onClickStart }: OnBoadingType) => {
  const navigate = useNavigate();
  const flickingRef = useRef<Flicking>(null);
  const beforeOnboadingRef = useRef<boolean>(false);
  const [isShowBottomBtn, setIsShowButtomBtn] = useState<boolean>(false);
  const onBoadingDataList = [
    {
      title: '월별 매출 · 수익 계산',
      desc: '간단한 입력만으로 월별 매출과<br/>수익을 계산할 수 있어요.',
      imageUrl: 'https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/drive_onboading_1.png',
    },
    {
      title: '간편한 미수금 관리',
      desc: '‘전체 수금처리’ 버튼으로 미수금도<br/>한 번에 처리가 가능해요.',
      imageUrl: 'https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/drive_onboading_2.png',
    },
    {
      title: '상하차지 자동 거리 계산',
      desc: '카카오주소 연동 시스템으로<br/>상하차지 거리를 자동으로 계산해요.',
      imageUrl: 'https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/drive_onboading_3.png',
    },
    {
      title: '주유비 자동 입력 기능',
      desc: '영수증 사진을 업로드하면<br/>주유비가 자동으로 입력돼요.',
      imageUrl: 'https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/drive_onboading_4.png',
    },
  ];
  const bullet = onBoadingDataList.map((item, index) => ({ isNow: index === 0 }));
  const [bulletList, setBulletList] = useState(bullet);
  const onClickBullet = (index: number) => {
    if (flickingRef.current) {
      flickingRef.current.moveTo(index).catch(() => void 0);
    }
  };

  useEffect(() => {
    if (flickingRef.current) {
      flickingRef.current.on(EVENTS.CHANGED, (event) => {
        const updatedBullet = onBoadingDataList.map((_, index) => ({ isNow: index === event.index }));
        setBulletList(updatedBullet);
      });
    }
  }, [onBoadingDataList]);

  const onClickGoToNext = (index: number) => {
    if (flickingRef.current) {
      flickingRef.current.next().catch(() => void 0);
    }
  };

  useEffect(() => {
    return () => {};
  }, []);

  const handleClickGoDriveHome = () => {
    localStorage.setItem('isFirstDriveHome', String(beforeOnboadingRef.current));
    onClickStart();
  };

  useEffect(() => {
    setIsShowButtomBtn(flickingRef.current?.index === 3);
  }, [flickingRef.current?.index]);

  return (
    <div className="min-w-[280px] max-w-[720px] w-full min-h-[540px] h-[100dvh] bg-gradient-to-b-linear-blue flex flex-col justify-center">
      <div className="pt-20">
        <Flicking
          ref={flickingRef}
          align="center"
          circular={false}
          renderOnlyVisible={true}
          className="z-0 w-full"
          moveType="strict"
          circularFallback="bound"
        >
          {onBoadingDataList.map((data, index) => (
            <div
              className="flex flex-col justify-center items-center w-full h-full text-gray-0"
              key={index}
              onClick={() => onClickGoToNext(index)}
            >
              <h2 className={`text-[28px] text-center leading-[32px] font-extrabold`}>{data.title}</h2>
              <p
                className="font-medium text-[18px] leading-[22px] pt-3 pb-[17px] text-center"
                dangerouslySetInnerHTML={{ __html: data.desc }}
              ></p>
              <div className="w-[234px] h-[268px]">
                <img src={data.imageUrl as string} alt="onboading_img" className="w-full h-full object-cover"></img>
              </div>
            </div>
          ))}
        </Flicking>
        <div className="flex justify-center py-8 cursor-pointer z-10">
          {bulletList.map((data, index) => (
            <div
              key={index}
              className={`w-3 h-3 rounded-full cursor-pointer m-1 bg-gray-0 ${data.isNow ? '' : 'opacity-60'}`}
              onClick={(e) => onClickBullet(index)}
            ></div>
          ))}
        </div>
      </div>

      <div
        className="absolute bottom-0 flex items-end justify-center py-8 cursor-pointer z-10 px-4 min-w-[280px] max-w-[720px] w-full"
        style={{ visibility: isShowBottomBtn ? 'visible' : 'hidden' }}
      >
        <BasicButton
          name="운행일지 작성하러 가기"
          bgColor={colors.gray[0]}
          borderColor={colors.gray[0]}
          textColor={colors.primary}
          fontSize={16}
          height={52}
          fontWeight="bold"
          onClick={() => handleClickGoDriveHome()}
        />
      </div>
    </div>
  );
};

export default DriveOnBoading;
