import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { getNoticeDetail } from '@/api/public';
import MenuHeader from '@/components/Header/MenuHeader';
import { asYYYYMMDD } from '@/utils/common';

const NoticeDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data } = useQuery(['get-notice-detail', id], () => getNoticeDetail(id), {
    onSuccess: (res) => {},
    onError: () => {
      navigate('/notFound', { replace: true });
    },
    refetchOnWindowFocus: false,
    retry: false,
  });

  return (
    <div className="w-full">
      <MenuHeader title="공지사항" />
      <div className="pt-[60px] px-4">
        <div className="border-b border-gray-4 py-6">
          <p className="font-semibold text-[20px] leading-[24px]">{data?.title}</p>
          <p className="text-gray-6 text-[14px] leading-[17px] mt-4">{asYYYYMMDD(data?.createdDate, true)}</p>
        </div>
        <div className="text-gray-9 py-6" dangerouslySetInnerHTML={{ __html: data?.contents }} />
      </div>
    </div>
  );
};

export default NoticeDetail;
