import React from 'react';

import { EyesCloseOutlineIcon, EyesOpenOutlineIcon } from '@/components/Icon';
import { colors } from '@/const/colors';

interface PasswordSuffixProps {
  isShow: boolean;
  toggleVisibility: () => void;
}

const PasswordSuffix: React.FC<PasswordSuffixProps> = ({ isShow, toggleVisibility }) => {
  return !isShow ? (
    <EyesCloseOutlineIcon className="cursor-pointer" color={colors.gray[5]} onClick={toggleVisibility} />
  ) : (
    <EyesOpenOutlineIcon className="cursor-pointer" onClick={toggleVisibility} />
  );
};

export default PasswordSuffix;
