import React, { ChangeEvent, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link, NavigationType, useLocation, useNavigate, useNavigationType } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import BasicButton from '@/components/Common/Button/BasicButton';
import PasswordSuffix from '@/components/Common/Input/TextInput/PasswordSuffix';
import TextInputLabelOff from '@/components/Common/Input/TextInputLabelOff';
import BubbleComponent from '@/components/Common/Tooltip/BubbleComponent';
import { ChevronRightIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { KAKAO_REDIRECT_URL, NAVER_AUTH_URL } from '@/const/url';
import { useToastContext } from '@/contexts/Common/ToastContext';
import useSetMemberData from '@/hooks/useSetMemberData';

interface LoginInfo {
  phoneNumber: string;
  password: string;
}

const Login = () => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const [bubblePosition, setBubblePosition] = useState(0);

  const [isShowPassword, setIsShowPassword] = useState(false);

  useEffect(() => {
    if (buttonRef.current) {
      const buttonWidth = buttonRef.current.clientWidth;
      setBubblePosition(buttonWidth / 4);
    }
  }, []);

  const navigate = useNavigate();
  const setMemberData = useSetMemberData('');
  const navigationType = useNavigationType();
  const location = useLocation();
  const redirectUrl = location.state?.redirectUrl;
  const [isSocialLogin, setIsSocialLogin] = useState<boolean>(false);
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const { showToast } = useToastContext();
  const [cookies, setCookie, removeCookie] = useCookies(['redirect_url']);

  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const [loginInfo, setLoginInfo] = useState<LoginInfo>({
    phoneNumber: '',
    password: '',
  });
  const [isErrorInput, setIsErrorInput] = useState(false);

  const onChangeMemberInfo = (e: ChangeEvent<HTMLInputElement>) => {
    setLoginInfo({ ...loginInfo, [e.target.name]: e.target.value });
  };

  const onChangeLoginType = () => {
    setIsSocialLogin(!isSocialLogin);
  };

  const goToPage = (href: string) => {
    navigate(href);
  };

  const goHref = (href: string) => {
    window.location.href = `${href}`;
  };

  const initKakao = () => {
    const Kakao = window.Kakao;
    if (Kakao && !Kakao.isInitialized()) {
      Kakao.init(process.env.KAKAO_KEY);
    }
  };

  useEffect(() => {
    initKakao();
  }, []);

  const setRedirectUrlCookie = () => {
    const expireDate = new Date();
    expireDate.setMinutes(expireDate.getMinutes() + 10);
    setCookie('redirect_url', redirectUrl || '/', { expires: expireDate });
  };

  const loginWithKakao = () => {
    setRedirectUrlCookie();

    const Kakao = window.Kakao;
    Kakao.Auth.authorize({
      redirectUri: KAKAO_REDIRECT_URL,
      scope: 'name,profile_image,phone_number,account_email',
      prompt: 'select_account',
      throughTalk: true,
    });
  };

  const loginWithNaver = () => {
    setRedirectUrlCookie();
    goHref(`${NAVER_AUTH_URL}`);
  };

  const onClickLogin = () => {
    apiManager
      .post('/auth/login', loginInfo, {
        withCredentials: true,
      })
      .then((response) => {
        setIsErrorInput(false);
        const { token, loginByTempPassword } = response.data;
        localStorage.setItem('token', token);

        const deviceToken = localStorage.getItem('deviceToken');
        if (deviceToken) {
          apiManager.post('/api/v1/fire-base/device-token', { deviceToken: deviceToken });
        }

        if (loginByTempPassword) {
          navigate('/member/password');
          setMemberData(token);
        } else {
          navigate(redirectUrl || '/');
          setMemberData(token);
        }
      })
      .catch((error) => {
        const loginErrorCodes = ['PASSWORD_IS_EMPTY', 'PHONE_NUMBER_IS_EMPTY', 'PHONE_NUMBER_OR_PASSWORD_IS_WRONG'];
        if (loginErrorCodes.includes(error?.code)) {
          showToast(`${error.message}`, 'error', 'bottom');
        }
        setIsErrorInput(true);
      });
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (!!!loginInfo.phoneNumber) {
        showToast(`휴대폰 번호를 입력해주세요.`, 'error', 'top');
        return;
      } else if (!!!loginInfo.password) {
        showToast(`비밀번호를 입력해주세요.`, 'error', 'top');
        return;
      } else {
        onClickLogin();
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem('token') && navigationType === NavigationType.Pop) {
      navigate(-1);
    }
    if (location.state?.isSocial === false) {
      setIsSocialLogin(false);
    } else {
      setIsSocialLogin(true);
    }
  }, []);

  const scrollIntoView = (e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e) {
      const { name } = e.target;
      if (name === 'phoneNumber') {
        phoneNumberRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      } else if (name === 'password') {
        passwordRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    } else {
      phoneNumberRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      passwordRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
  };

  useLayoutEffect(() => {
    const initialHeight = document.documentElement.clientHeight;

    if (initialHeight < 650) {
      setIsKeyboardOpen(true);
    }

    const handleResize = () => {
      const currentHeight = window.innerHeight;
      setIsKeyboardOpen(initialHeight > currentHeight);
    };

    const detectMobileKeyboard = () => {
      handleResize();
      scrollIntoView();
    };

    window.addEventListener('resize', detectMobileKeyboard);
    return () => {
      window.removeEventListener('resize', detectMobileKeyboard);
    };
  });

  const togglePasswordVisibility = (isShow: boolean, setIsShow: React.Dispatch<React.SetStateAction<boolean>>) => {
    setIsShow(!isShow);
  };

  return (
    <>
      <div className="w-full h-[100dvh] p-4 flex flex-col justify-between relative min-h-[540px]">
        <div className="flex justify-end items-center pt-2 text-gray-8" onClick={() => navigate('/')}>
          <span className="mr-1">홈으로 가기</span>
          <ChevronRightIcon color={colors.gray[8]} width={16} height={16}></ChevronRightIcon>
        </div>

        <div
          className={`absolute ${
            isKeyboardOpen ? 'top-0' : 'top-[15%]'
          } left-4 font-bold text-4xl xxs:text-3xl xs:text-3xl leading-normal ml-2 mt-12`}
        >
          <img
            src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/logo.png"
            alt="직트럭 로고"
            className="mb-4 w-20"
          ></img>
          화물차 거래의
          <br /> 새로운 기준
        </div>

        {isSocialLogin ? (
          <div className="relative" ref={buttonRef}>
            <div
              className={`absolute top-[-100px] transform -translate-x-1/2 w-max`}
              style={{
                left: `calc(50% - ${bubblePosition}px)`,
              }}
            >
              <BubbleComponent
                text="<span class='text-gray-8 text-[14px]'><strong>3초만에 회원가입</strong>하고<br/>직트럭의 모든 서비스를 이용해보세요!</span>"
                bgColor={colors.gray[1]}
                padding="14px 20px"
                shadow
              ></BubbleComponent>
            </div>
            <div className="flex flex-col gap-4">
              <BasicButton
                name="카카오톡으로 시작하기"
                icon={
                  <img
                    src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/kakao_icon.png"
                    alt="kakao_icon"
                    className="absolute left-4 top-3 w-8 h-8"
                  ></img>
                }
                bgColor={colors.kakao}
                borderColor={colors.kakao}
                textColor={colors.gray[10]}
                fontSize={18}
                height={56}
                fontWeight="semibold"
                onClick={() => {
                  loginWithKakao();
                }}
              ></BasicButton>
              <BasicButton
                name="네이버로 시작하기"
                icon={
                  <img
                    src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/naver_icon.png"
                    alt="naver_icon"
                    className="absolute left-4 top-3 w-8 h-8"
                  ></img>
                }
                bgColor={colors.naver}
                borderColor={colors.naver}
                textColor={colors.gray[0]}
                fontSize={18}
                height={56}
                borderRadius={12}
                fontWeight="semibold"
                onClick={loginWithNaver}
              ></BasicButton>
            </div>
            <p
              className="my-6 underline underline-offset-4 text-center text-gray-6 cursor-pointer text-lg"
              onClick={onChangeLoginType}
            >
              전화번호로 로그인
            </p>
          </div>
        ) : (
          <div>
            <div>
              <div className="flex flex-col gap-[30px]">
                <TextInputLabelOff
                  ref={phoneNumberRef}
                  name="phoneNumber"
                  placeholder="휴대폰 번호를 입력하세요."
                  value={loginInfo.phoneNumber}
                  fontSize={18}
                  onChange={(e) => onChangeMemberInfo(e)}
                  onFocus={(e) => scrollIntoView(e)}
                  error={isErrorInput}
                  type="number"
                  inputMode="numeric"
                ></TextInputLabelOff>
                <TextInputLabelOff
                  ref={passwordRef}
                  name="password"
                  placeholder="비밀번호를 입력하세요."
                  value={loginInfo.password}
                  fontSize={18}
                  onChange={(e) => onChangeMemberInfo(e)}
                  onFocus={(e) => scrollIntoView(e)}
                  error={isErrorInput}
                  type={isShowPassword ? 'text' : 'password'}
                  onKeyDown={(e) => handleKeyPress(e)}
                  suffix={
                    <PasswordSuffix
                      isShow={isShowPassword}
                      toggleVisibility={() => togglePasswordVisibility(isShowPassword, setIsShowPassword)}
                    />
                  }
                ></TextInputLabelOff>
              </div>
              <div className="flex justify-between mt-[18px] mb-12">
                <a
                  className="text-gray-6 block underline underline-offset-4 cursor-pointer text-sm font-semibold"
                  onClick={() => goToPage('/signup')}
                >
                  아직 회원이 아니신가요?
                </a>
                <Link to="/forgot-password">
                  <span className="text-gray-6 block font-semibold cursor-pointer text-sm">비밀번호 찾기</span>
                </Link>
              </div>
            </div>

            <BasicButton
              name="로그인"
              bgColor={colors.primary}
              borderColor={colors.primary}
              textColor={colors.gray[0]}
              fontSize={16}
              height={52}
              borderRadius={12}
              fontWeight="semibold"
              onClick={() => onClickLogin()}
              isDisabled={!loginInfo.phoneNumber || !loginInfo.password}
            ></BasicButton>

            <p
              className="my-6 underline underline-offset-4 text-center text-gray-6 cursor-pointer text-lg"
              onClick={onChangeLoginType}
            >
              소셜 로그인
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default Login;
