import React from 'react';

import CapitalInfo from './DetailTab/CapitalInfo';
import ProductHistory from './DetailTab/ProductHistory';
import ProductInfo from './DetailTab/ProductInfo';
import BasicTabs from '@/components/Common/Tabs/BasicTabs';
import '@/css/tailwind.css';

interface ProductDetailProps {
  product: ProductDetailResponse;
  value: number;
  onChange: (newValue: number) => void | boolean;
}

export default function ProductDetailTab({ product, value, onChange }: ProductDetailProps) {
  const labelList = [
    { icon: null, title: '차량 상세 정보' },
    { icon: null, title: '차량 이력' },
    { icon: null, title: '할부 계산기' },
  ];

  return (
    <BasicTabs labelList={labelList} value={value} onChange={onChange}>
      <ProductInfo product={product}></ProductInfo>
      <ProductHistory product={product}></ProductHistory>
      <CapitalInfo price={String(product.price)}></CapitalInfo>
    </BasicTabs>
  );
}
