import { useSetAtom } from 'jotai';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import BasicButton from '@/components/Common/Button/BasicButton';
import TextInputLabelOff from '@/components/Common/Input/TextInputLabelOff';
import LoadingSpinner from '@/components/Common/LoadingSpinner';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import { colors } from '@/const/colors';
import { PRODUCTS_CAN_SALES_MAX_THREE } from '@/const/errorCode';
import { BEFORE_SALE, COMPLETED, ORIGIN_DATA_REGISTER, PAUSE, SALE } from '@/const/productStatus';
import { DUPLICATE_VEHICLE_WARNING } from '@/const/warningMessages';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { productsFormAtom } from '@/store/products';
import { getPageName } from '@/utils/Products/productUtils';

interface LicensePlateInputProps {
  updatePage: (pageNum: number) => void;
  ownerInfo: OwnerInfo;
  setOwnerInfo: React.Dispatch<React.SetStateAction<OwnerInfo>>;
  salesType?: String;
}
interface ErrorInfoProps {
  errorInfo: ErrorInfo | undefined;
  setErrorInfo: React.Dispatch<React.SetStateAction<ErrorInfo | undefined>>;
}

const OwnerNameInput: React.FC<LicensePlateInputProps & ErrorInfoProps> = ({
  updatePage,
  ownerInfo,
  setOwnerInfo,
  errorInfo,
  setErrorInfo,
  salesType,
}) => {
  const { showToast } = useToastContext();
  const setProductFormData = useSetAtom(productsFormAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const navigate = useNavigate();

  const [popupProps, setPopupProps] = useState<BasicPopupProps>({
    isShow: false,
    title: '',
    textContent: '',
    textLeftBtn: '',
    onClickLeftBtn: () => {},
    textRightBtn: '',
    onClickRightBtn: () => {},
  });

  const close = () => {
    setIsShow(false);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isLoading) return;
    const { value } = e.currentTarget;
    if (e.key === 'Enter') {
      if (!value) {
        setErrorInfo({
          ...errorInfo,
          ownerNameError: true,
          ownerNameErrorMessage: '소유자 정보가 맞지 않습니다. 다시 입력해주세요.',
          licenseNumberError: false,
          licenseNumberErrorMessage: '',
        });
        return;
      } else {
        onClickGetData(value);
      }
    }
  };

  const onClickGoToOriginDataInfo = (id: number, productOriginData: ProductDetailResponse) => {
    close();
    navigate(`/products/sales/info/${id}`, { state: { productOriginData, type: salesType } });
  };

  const onClickContinueEdit = (data: ProductDetailResponse) => {
    const pageName = getPageName(data);
    const prefixPath = '/products/sales';
    navigate(`${prefixPath}/${pageName}/${data.id}`);
  };

  const onClickGetData = (value: string) => {
    if (isLoading) return;
    if (!value) {
      setErrorInfo({
        ...errorInfo,
        ownerNameError: true,
        ownerNameErrorMessage: '소유자 정보가 맞지 않습니다. 다시 입력해주세요.',
        licenseNumberError: false,
        licenseNumberErrorMessage: '',
      });
      return;
    }
    setIsLoading(true);

    const queryParams = new URLSearchParams();
    queryParams.append('carNumber', ownerInfo.licenseNumber);
    if (ownerInfo.name) {
      queryParams.append('ownerName', ownerInfo.name);
    }
    apiManager
      .get('/api/v1/products/car-register?' + queryParams.toString())
      .then((response) => {
        const responseData: ProductDetailResponse = response.data;

        setIsLoading(false);
        setIsShow(true);
        setProductFormData(responseData);
        if (responseData.manufacturerCategories.code === 'ETC') {
          setProductFormData({ ...responseData, model: { id: 45, name: '기타(쌍용 외)' } });
        } else {
          setProductFormData(responseData);
        }
        //원부데이터 조회에 따른 팝업 분기처리
        if (responseData) {
          if (responseData.status?.code === ORIGIN_DATA_REGISTER || responseData.status?.code === COMPLETED) {
            setPopupProps({
              ...popupProps,
              isShow: true,
              title: responseData?.year
                ? `<span class="break-all">${responseData?.truckName}</span> ${responseData?.year}연식이 맞으신가요?`
                : `<span class="break-all">${responseData?.truckName}</span> 차량이 맞으신가요?`,
              textContent: responseData.isDuplicateProduct ? DUPLICATE_VEHICLE_WARNING : '',
              textLeftBtn: '아니요',
              onClickLeftBtn: close,
              textRightBtn: '네, 맞습니다',
              onClickRightBtn: () => onClickGoToOriginDataInfo(responseData?.id, responseData),
            });
          } else if (responseData.status?.code === BEFORE_SALE) {
            setPopupProps({
              ...popupProps,
              isShow: true,
              title: responseData?.year
                ? `${responseData?.truckName} ${responseData?.year}연식`
                : `${responseData?.truckName}`,
              textContent: '이전에 등록한 정보가 있습니다.<br/>이어서 수정하시겠어요?',
              textLeftBtn: '취소',
              onClickLeftBtn: close,
              textRightBtn: '수정',
              onClickRightBtn: () => onClickContinueEdit(responseData),
            });
          } else if (responseData.status?.code === SALE) {
            setPopupProps({
              ...popupProps,
              isShow: true,
              title: responseData?.year
                ? `${responseData?.truckName} ${responseData?.year}연식`
                : `${responseData?.truckName}`,
              textContent: '이미 판매중인 차량입니다.',
              textLeftBtn: '취소',
              onClickLeftBtn: close,
              textRightBtn: '내차관리로',
              onClickRightBtn: () => onClickGoMyProductPage(),
            });
          } else if (responseData.status?.code === PAUSE) {
            setPopupProps({
              ...popupProps,
              isShow: true,
              textContent:
                '이 차량은 현재 판매 중지된 상태입니다.<br/>재판매를 원하실 경우,<br/>판매 상태를 다시 활성화해주세요.',
              textRightBtn: '확인',
              onClickRightBtn: close,
            });
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        const licenseNumberErrorCodes = ['NOT_EXIST_RESPONSE_BY_DATA_HUB', 'MISSING_CAR_NUMBER'];
        const ownerNameErrorCodes = [
          'NOT_MATCH_OWNER_NAME',
          'MISSING_OWNER_NAME',
          PRODUCTS_CAN_SALES_MAX_THREE,
          'NOT_MATCH_CAR_INFORMATION',
        ];

        if (licenseNumberErrorCodes.includes(error?.code)) {
          setErrorInfo({
            ...errorInfo,
            licenseNumberError: true,
            licenseNumberErrorMessage: `${error?.message}`,
            ownerNameError: false,
            ownerNameErrorMessage: '',
          });
          updatePage(1);
          showToast(`${error?.message}`, 'error', 'bottom');
        } else if (ownerNameErrorCodes.includes(error?.code)) {
          setErrorInfo({
            ...errorInfo,
            ownerNameError: true,
            ownerNameErrorMessage: `${error?.message}`,
            licenseNumberError: false,
            licenseNumberErrorMessage: '',
          });
        } else if (error?.code === 'DATA_HUB_CAR_INFO_FOUND_FAIL') {
          updatePage(3);
        } else {
          updatePage(1);
        }
      });
  };

  const onClickGoMyProductPage = () => {
    navigate('/products/my');
  };

  const onChangeOwnerName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setErrorInfo({
      ...errorInfo,
      ownerNameError: false,
      ownerNameErrorMessage: '',
      licenseNumberError: false,
      licenseNumberErrorMessage: '',
    });
    setOwnerInfo({ ...ownerInfo, name: value });
  };

  return (
    <div className="pt-[60px] px-4">
      <div className="flex flex-col justify-between w-full">
        {isLoading && (
          <LoadingSpinner text="데이터를 조회중입니다. 잠시만 기다려주세요..." isTextChanging></LoadingSpinner>
        )}
        <div>
          <h2 className="text-2xl font-bold text-gray-8 mt-6">소유자명을 입력해주세요</h2>
          <div className="text-gray-7 font-medium text-[15px] leading-[19px] mt-3">
            차량번호와 소유자명을 입력하면
            <br /> 소유한 차량의 기본 정보를 확인할 수 있어요.
          </div>
          <div className="px-[30px] mt-[30px] mb-2">
            <img
              src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/vehicle_registration_certificate.png"
              alt="vehicle_registration_certificate"
              className="w-full h-full"
            />
          </div>
          <div className="rounded-lg bg-blue-0 p-4 mb-[30px] text-gray-8 text-sm">
            <p>* 소유자명은 자동차 등록증에서 확인이 가능합니다.</p>
            <p className="text-red">* 법인차량은 "(주)", "주식회사" 단어까지 정확히 입력해주세요.</p>
          </div>
          <div className="relative w-full flex justify-between align-center">
            <TextInputLabelOff
              name="ownerName"
              placeholder="예) 주식회사 OOO, 홍길동"
              value={ownerInfo.name}
              height={50}
              fontSize={18}
              onChange={onChangeOwnerName}
              onKeyDown={handleKeyPress}
              error={errorInfo?.ownerNameError}
              errorMsg={errorInfo?.ownerNameErrorMessage}
              type="text"
              paddingRight={82}
            ></TextInputLabelOff>
            <div className="absolute right-0 bottom-2">
              <BasicButton
                name="조회"
                bgColor={colors.primary}
                borderColor={colors.primary}
                textColor={colors.gray[0]}
                fontSize={14}
                width={72}
                height={40}
                borderRadius={8}
                fontWeight="semibold"
                onClick={() => onClickGetData(ownerInfo.name)}
                isDisabled={!ownerInfo.name}
              ></BasicButton>
            </div>
          </div>
        </div>
        <BasicPopup
          isShow={isShow}
          title={popupProps.title}
          textContent={popupProps.textContent}
          textLeftBtn={popupProps.textLeftBtn}
          onClickLeftBtn={popupProps.onClickLeftBtn}
          textRightBtn={popupProps.textRightBtn}
          onClickRightBtn={popupProps.onClickRightBtn}
        ></BasicPopup>
      </div>
    </div>
  );
};

export default OwnerNameInput;
