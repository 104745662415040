import React from 'react';

const AccompanyingGuideView = () => {
  return (
    <>
      <div className="flex flex-col items-center bg-gray-1 py-10">
        <p className="text-gray-700 text-[15px] font-medium text-center">중고화물차 구매가 망설여지나요?</p>
        <p className="text-gray-700 text-2xl font-extrabold text-center pt-6 pb-10">
          차량 구매 동행 서비스로
          <br />
          고민을 해결하세요
        </p>
        <div className="flex justify-center items-center max-w-[280px] w-[152px] h-[148px]">
          <img
            src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/purchase-companion-service1.png"
            alt="구매 동행 서비스 트럭 이미지"
            className="w-full"
          />
        </div>
      </div>

      <div className="py-11 flex flex-col justify-center items-center">
        <p className="text-2xl text-gray-8 text-center font-bold pb-8">차량 구매 동행 서비스란?</p>
        <div className="flex justify-center items-center max-w-[280px] pb-10 w-[160px] h-[122px]">
          <img
            src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/purchase-companion-service2.png"
            alt="구매 동행 서비스 트럭 이미지"
            className="w-full"
          />
        </div>
        <p className="text-gray-8 text-center pb-5 text-pretty mx-7 break-keep">
          고객님이 중고화물차를 구매하기 전, 차량 전문가가 동행하여 <br className="hidden sm550:block" />
          차량 점검부터 적절한 금액 협상까지 도와드리는 서비스입니다.
        </p>
        <p className="text-gray-8 text-center text-pretty mx-7">
          차량 전문가는 관련 자격증을 보유한 전문가들로 구성되어 있습니다.
        </p>
      </div>

      <div className="flex flex-col justify-center gap-4 items-center bg-gray-1 py-[46px] px-[34px] xxs:px-4">
        <div className="text-gray-8 text-[24px] font-bold mb-[18px]">이런 서비스를 제공해요</div>

        <div className="flex flex-col gap-4 bg-gray-0 p-5 rounded-[12px] w-full">
          <div className="flex font-semibold">
            <div className="flex justify-center w-6 h-6 bg-primary rounded-full text-gray-0 font-bold mr-2">1</div>
            차량 점검
          </div>
          <dl>
            <dt className="text-[14px] font-medium">사고 여부</dt>
            <dd className="text-gray-7 text-[12px]">· 차량의 사고 이력을 확인합니다.</dd>
          </dl>
          <dl>
            <dt className="text-[14px] font-medium">각종 누유</dt>
            <dd className="text-gray-7 text-[12px]">· 엔진 및 기타 부위의 누유 여부를 점검합니다.</dd>
          </dl>
          <dl>
            <dt className="text-[14px] font-medium">엔진 및 미션</dt>
            <dd className="text-gray-7 text-[12px]">· 엔진과 미션 상태를 확인하여 성능을 평가합니다.</dd>
          </dl>
        </div>

        <div className="flex flex-col gap-4 bg-gray-0 p-5 rounded-[12px] w-full">
          <div className="flex font-semibold">
            <div className="flex justify-center w-6 h-6 bg-primary rounded-full text-gray-0 font-bold mr-2">2</div>
            특장 점검
          </div>
          <dl>
            <dt className="text-[14px] font-medium">제원 상태 측정</dt>
            <dd className="text-gray-7 text-[12px]">· 차량의 제원 상태를 측정하여 정상 범위 내인지 확인합니다.</dd>
          </dl>
          <dl>
            <dt className="text-[14px] font-medium">특장 하자 체크</dt>
            <dd className="text-gray-7 text-[12px]">· 특장(적재함) 부분에 문제점이 있는지 확인합니다.</dd>
          </dl>
          <dl>
            <dt className="text-[14px] font-medium">수리 발생 견적</dt>
            <dd className="text-gray-7 text-[12px]">· 필요한 수리 비용을 산정합니다.</dd>
          </dl>
        </div>

        <div className="flex flex-col gap-4 bg-gray-0 p-5 rounded-[12px] w-full">
          <div className="flex font-semibold">
            <div className="flex justify-center w-6 h-6 bg-primary rounded-full text-gray-0 font-bold mr-2">3</div>
            추가 서비스
          </div>
          <dl>
            <dt className="text-[14px] font-medium">차량 금액 협의</dt>
            <dd className="text-gray-7 text-[12px]">· 차량을 점검 후 적절한 가격 협상을 도와드립니다.</dd>
          </dl>
          <dl>
            <dt className="text-[14px] font-medium">계약 절차 협의</dt>
            <dd className="text-gray-7 text-[12px]">· 계약서 작성 및 절차를 도와드립니다.</dd>
          </dl>
          <dl>
            <dt className="text-[14px] font-medium">차량 잔금 처리</dt>
            <dd className="text-gray-7 text-[12px]">· 저당 및 압류 상태를 확인하고 잔금 처리를 지원합니다.</dd>
          </dl>
        </div>

        <div className="text-gray-7 font-medium text-[14px] mt-[20px] text-center break-keep">
          해당 서비스는 안전하고 투명한 중고화물차 구매 절차를 지원하여, 차량 상태에 따른 문제에 대해 걱정없이 구매하실
          수 있도록 도와드립니다.
        </div>
        <div className="text-gray-6 text-[12px]">※ 해당 서비스는 유료 서비스입니다.</div>
      </div>
    </>
  );
};

export default AccompanyingGuideView;
