import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import MenuHeader from '@/components/Header/MenuHeader';
import PriceTrendSearchForm from '@/components/PriceTrend/PriceTrendSearchForm';

const PriceTrendSearchFormPage = () => {
  const location = useLocation();
  const ownerInfo = location.state?.ownerInfo;
  const productOriginData = location.state?.productOriginData;
  const [productSearchParams, setProductSearchParams] = useState<ProductSearchParams>({
    tons: '',
    year: '',
    model: { id: 0, name: '' },
    manufacturerCategories: { id: 0, name: '', code: '' },
    loaded: { code: '', desc: '' },
    loadedInnerLength: 0,
    axis: { code: '', desc: '' },
    productId: null,
    status: null,
  });

  return (
    <>
      <MenuHeader title="시세검색"></MenuHeader>
      <div className="w-full p-4 pb-24 pt-[44px]">
        <>
          <PriceTrendSearchForm
            ownerInfo={ownerInfo}
            productOriginData={productOriginData}
            productSearchParams={productSearchParams}
            setProductSearchParams={setProductSearchParams}
          ></PriceTrendSearchForm>
        </>
      </div>
    </>
  );
};

export default PriceTrendSearchFormPage;
