import dayjs from 'dayjs';
import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Tour, { ReactourStep } from 'reactour';

import { getDriveHistory } from '@/api/public';
import { ButtonProps } from '@/components/Common/Button/BasicButton';
import CustomCalendar from '@/components/Common/Calendar/CustomCalendar';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import DownToUpDetailPopup from '@/components/Common/Popup/DownToUpDetailPopup';
import HomePopup from '@/components/Common/Popup/HomePopup';
import TooltipComponent from '@/components/Common/Tooltip/TooltipComponent';
import DriveForm from '@/components/Drive/DriveForm';
import DriveListView from '@/components/Drive/DriveListView';
import DriveOnBoading from '@/components/Drive/DriveOnboading';
import FuelingHistoryForm from '@/components/Drive/FuelingHistoryForm';
import RepairHistoryForm from '@/components/Drive/RepairHistoryForm';
import MenuHeader, { MenuHeaderIcon } from '@/components/Header/MenuHeader';
import {
  CheckCircleBoldIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  Cog6ToothIcon,
  DeliveryTruckIcon,
  HomeOutlineIcon,
  PlusCircleBoldIcon,
  PlusSmallIcon,
} from '@/components/Icon';
import { colors } from '@/const/colors';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import { driveSearchParamsAtom } from '@/store/drive';
import { feulingHistorySearchParamsFromAtom } from '@/store/fuelingHistory';
import { repairHistorySearchParamsAtom } from '@/store/repairHistory';
import { formatNumber, formatYYYYMM, formatYYYYMMDD, getFormatDate } from '@/utils/common';
import { Button } from '@mui/material';

interface DriveHistorySearchParams {
  driveVehicleInfoId: number;
  baseYearMonth: string;
  baseDay: string;
}

export interface PopupInfo {
  name: string;
  type: string;
}

const popupImage: Banner = {
  id: 0,
  contents: 'https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/driveTutorialPopup.jpg',
  type: { code: '', desc: '' },
  bannerLocation: { code: '', desc: '' },
  fileName: '',
  contentsType: { code: '', desc: '' },
  tracking: false,
  display: false,
};

const DriveHome = () => {
  const navigate = useNavigate();
  const { showToast } = useToastContext();
  const { member } = useMemberContext();
  const [step, setStep] = useState<number>();

  const [isShowOnBoading, setIsShowOnBoading] = useState<boolean>();

  const [driveVehicleInfoId, setDriveVehicleInfoId] = useState(member?.driveVehicleInfo?.id);

  const [isShow, setIsShow] = useState(false);
  const [isExpanded, setIsExpanded] = useState<Record<string, boolean>>({ fixedCost: false, income: false });

  const [clickedItemIndex, setClickedItemIndex] = useState<number | null>(null);

  const setFuelingHistorySearchParams = useSetAtom(feulingHistorySearchParamsFromAtom);
  const setRepairHistorySearchParams = useSetAtom(repairHistorySearchParamsAtom);

  const [searchParams] = useAtom(driveSearchParamsAtom);
  const setSearchParams = useSetAtom(driveSearchParamsAtom);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date(searchParams.baseDay) || new Date());

  const [isShowPopup, setIsShowPopup] = useState<boolean>();
  const [isShowDriveFormPopup, setIsShowDriveFormPopup] = useState(false);
  const [popupInfo, setPopupInfo] = useState({
    type: 'drive',
    name: '일지 추가',
  });

  const [isTourOpen, setIsTourOpen] = useState<boolean>(false);
  const [isShowFinalStep, setIsShowFinalStep] = useState(false);

  useEffect(() => {
    if (member) {
      setDriveVehicleInfoId(member?.driveVehicleInfo?.id);
      setSearchParams({
        driveVehicleInfoId: member?.driveVehicleInfo?.id,
        baseYearMonth: formatYYYYMM(selectedDate),
        baseDay: formatYYYYMMDD(selectedDate),
      });
    }
  }, [member, selectedDate]);

  useEffect(() => {
    setIsShowOnBoading(localStorage.getItem('isFirstDriveHome') !== 'false');
  }, []);

  const onClickDate = (date: Date) => {
    if (!driveVehicleInfoId) {
      setIsShow(true);
      return;
    }
    setSelectedDate(date);
    setIsShowPopup(true);
    if (localStorage.getItem('driveTutorial') === 'true') {
      setTimeout(() => {
        setStep(1);
      }, 500);
    }
  };

  const updateSelectedDate = (date: Date) => {
    setSelectedDate(date);
  };

  const onClickExpandIcon = (type: string) => {
    setIsExpanded((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };

  const onClickGoToSetting = () => {
    navigate('/drive/vehicle');
  };

  const onClickGoToHome = () => {
    setSearchParams({ ...searchParams, baseYearMonth: formatYYYYMM(new Date()), baseDay: formatYYYYMMDD(new Date()) });
    navigate('/');
  };

  const onClickGoToManagement = (type: string) => {
    if (!driveVehicleInfoId) {
      setIsShow(true);
      return;
    }
    setFuelingHistorySearchParams({
      driveVehicleInfoId: driveVehicleInfoId,
      fuelingYearAndMonth: formatYYYYMM(selectedDate),
    });
    setRepairHistorySearchParams({
      driveVehicleInfoId: driveVehicleInfoId,
      repairYearAndMonth: formatYYYYMM(selectedDate),
    });
    navigate(`/drive/${type}`, { state: { selectedDate: selectedDate } });
  };

  const getDriveHistoryData = async (searchParams: DriveHistorySearchParams) => {
    try {
      const params = new URLSearchParams({
        driveVehicleInfoId: searchParams.driveVehicleInfoId.toString(),
        baseYearMonth: searchParams.baseYearMonth,
        baseDay: searchParams.baseDay,
      });
      const response = await getDriveHistory(params);
      const data: DriveInfoResponse = response.data;
      return data;
    } catch (error) {
      throw new Error('Error');
    }
  };

  const { data, isError, isLoading, isFetching, isSuccess, refetch } = useQuery(
    ['get-drive-info', searchParams],
    () => getDriveHistoryData(searchParams),
    {
      onSuccess: () => {},
      onError: () => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
      enabled: !!searchParams.driveVehicleInfoId && !!searchParams.baseYearMonth && !!searchParams.baseDay,
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60,
      staleTime: 1000 * 5,
      keepPreviousData: true,
    },
  );

  const menuHeaderIcons: MenuHeaderIcon[] = [
    {
      icon: <HomeOutlineIcon color={colors.gray[8]} width={26} height={26}></HomeOutlineIcon>,
      onClickIcon: onClickGoToHome,
    },
    {
      icon: <Cog6ToothIcon color={colors.gray[8]} width={24} height={24}></Cog6ToothIcon>,
      onClickIcon: onClickGoToSetting,
    },
  ];

  const onClosePopup = () => {
    setIsShowPopup(false);
  };

  const onCloseFormPopup = () => {
    setIsShowDriveFormPopup(false);
    setPopupInfo({
      type: 'drive',
      name: '일지 추가',
    });
  };

  const month = dayjs(selectedDate).format('M').toString();

  const clearSelectedItem = () => {
    setClickedItemIndex(null);
  };

  const onClickDetail = (type: string, name: string, index?: number) => {
    if (index !== null && index !== undefined) {
      setClickedItemIndex(index);
    }
    if (type === 'repair') {
      setClickedItemIndex(0);
    }

    setPopupInfo({
      type: type,
      name: name,
    });
    setIsShowDriveFormPopup(true);
  };

  const onClickAddBtn = (popupInfo: PopupInfo) => {
    setPopupInfo(popupInfo);
    setIsShowDriveFormPopup(true);
    setTimeout(() => {
      setStep(2);
    }, 500);
  };

  const onClickDriveAddBtn = () => {
    if (!driveVehicleInfoId) {
      setIsShow(true);
      return;
    }

    setPopupInfo({
      type: 'drive',
      name: '일지 추가',
    });
    setIsShowDriveFormPopup(true);
  };

  const onClickFuelAddBtn = () => {
    if (!driveVehicleInfoId) {
      setIsShow(true);
      return;
    }

    setPopupInfo({
      type: 'fuel',
      name: '주유비 추가',
    });
    setIsShowDriveFormPopup(true);
  };

  const onClickRepairAddBtn = () => {
    if (!driveVehicleInfoId) {
      setIsShow(true);
      return;
    }

    setPopupInfo({
      type: 'repair',
      name: '수리비 추가',
    });
    setIsShowDriveFormPopup(true);
  };

  const addButtons: ButtonProps[] = [
    {
      name: '일지 추가',
      bgColor: colors.blue[5],
      border: 'none',
      borderColor: '',
      textColor: colors.primary,
      fontSize: 14,
      fontWeight: 'normal',
      onClick: onClickDriveAddBtn,
      icon: <PlusSmallIcon color={colors.primary} />,
      borderRadius: 20,
      height: 0,
    },
    {
      name: '주유비 추가',
      bgColor: '#FFFFFF',
      border: '1px solid',
      borderColor: colors.gray[4],
      textColor: colors.gray[7],
      fontSize: 14,
      fontWeight: 'normal',
      onClick: onClickFuelAddBtn,
      icon: <PlusSmallIcon color={colors.gray[6]} />,
      borderRadius: 20,
      height: 0,
    },
    {
      name: '수리비 추가',
      bgColor: '#FFFFFF',
      border: '1px solid',
      borderColor: colors.gray[4],
      textColor: colors.gray[7],
      fontSize: 14,
      fontWeight: 'normal',
      onClick: onClickRepairAddBtn,
      icon: <PlusSmallIcon color={colors.gray[6]} />,
      borderRadius: 20,
      height: 0,
    },
  ];

  const steps: ReactourStep[] = [
    {
      selector: '.todayTile',
      content: ({ goTo, inDOM }) => (
        <div
          onClick={() => {
            onClickDate(new Date());
            setTimeout(() => {
              goTo(1);
            }, 500);
          }}
          className="flex justify-center items-center break-keep"
        >
          <div className="flex flex-col justify-center items-center bg-gray-0 rounded-md w-[30px] h-[30px] mr-2">
            <span className="text-gray-8 text-[6px] leading-[6px] m-0 p-0">수요일</span>
            <span className="text-gray-9 text-[14px] leading-[14px] font-bold mt-[2px] p-0">25</span>
          </div>
          <div>운행일지를 추가할 날짜를 선택해주세요.</div>
        </div>
      ),
      position: 'top',

      style: {
        backgroundColor: colors.gray[8],
        color: colors.gray[0],
        borderRadius: '10px',
        padding: '16px 20px',
      },
    },
    {
      selector: '.addDriveFormBtn',
      content: ({ goTo }) => (
        <div
          onClick={() => {
            setIsShowDriveFormPopup(true);
            setTimeout(() => {
              goTo(2);
            }, 500);
          }}
          className="flex justify-center items-center break-keep"
        >
          <PlusCircleBoldIcon color={colors.gray[0]} width={30} height={30}></PlusCircleBoldIcon>
          <div className="ml-[10px]">
            내역에서 [일지 추가] 버튼을 클릭해
            <br />
            오늘의 운행일지를 추가하세요.
          </div>
        </div>
      ),
      position: 'bottom',
      style: {
        backgroundColor: colors.gray[8],
        color: colors.gray[0],
        borderRadius: '10px',
        padding: '16px 20px',
      },
    },
    {
      selector: '.drive-form-type',
      content: () => (
        <div
          onClick={() => {
            setIsTourOpen(false);
            setIsShowFinalStep(true);
          }}
          className="flex justify-center items-center break-keep"
        >
          <CheckCircleBoldIcon color={colors.gray[0]} width={30} height={30}></CheckCircleBoldIcon>
          <span className="ml-[10px]">차량이 회차했다면 회차를 선택해주세요.</span>
        </div>
      ),
      position: 'bottom',
      style: {
        backgroundColor: colors.gray[8],
        color: colors.gray[0],
        borderRadius: '10px',
        padding: '16px 20px',
      },
    },
  ];

  useLayoutEffect(() => {
    if (data) {
      if (
        !!driveVehicleInfoId &&
        data?.isFirstDriveHistory &&
        (!localStorage.getItem('driveTutorial') || localStorage.getItem('driveTutorial') === 'true')
      ) {
        localStorage.setItem('driveTutorial', String(true));
        setIsTourOpen(true);
      } else {
        localStorage.setItem('driveTutorial', String(false));
      }
    }
  }, [driveVehicleInfoId, data]);

  const onClickFinalGuidePopup = () => {
    setIsShowFinalStep(false);
    localStorage.setItem('driveTutorial', 'false');
  };

  const onClickLastTutorial = () => {
    setIsTourOpen(false);
    setIsShowFinalStep(true);
  };

  const onClickGoToOutstandingAmountList = () => {
    navigate('/drive/outstanding-amount', { state: { selectedDate: selectedDate } });
  };

  const handleClickStartBtn = () => {
    setIsShowOnBoading(false);
  };

  return (
    <>
      {isShowOnBoading ? (
        <DriveOnBoading onClickStart={handleClickStartBtn}></DriveOnBoading>
      ) : (
        <>
          <MenuHeader title="운행일지" icons={menuHeaderIcons}></MenuHeader>
          <div className="drive-home w-full pt-[60px] h-screen">
            {!driveVehicleInfoId && (
              <div
                className="rounded-lg bg-primary p-4 m-4 flex justify-between items-center cursor-pointer"
                onClick={() => navigate('/drive/vehicle')}
              >
                <div>
                  <p className="text-lg font-bold mb-2 text-gray-0">차량 정보 등록하기</p>
                  <div className="text-gray-0 text-sm">
                    운행일지 작성을 위해
                    <br />
                    차량의 기본 정보를 입력해주세요.
                  </div>
                </div>
                <DeliveryTruckIcon color={colors.gray[0]}></DeliveryTruckIcon>
              </div>
            )}
            <div className="grid grid-cols-3 gap-2 px-2 py-3">
              {addButtons.map((button, index) => (
                <Button
                  key={index}
                  size="small"
                  sx={{
                    backgroundColor: button.bgColor,
                    color: button.textColor,
                    fontSize: button.fontSize,
                    fontWeight: button.fontWeight,
                    height: '40px',
                    borderRadius: button.borderRadius,
                    border: `${button.border ? button.border : '1px solid'} ${button.borderColor}`,
                    paddingY: '0px',
                    justifyContent: 'center',
                    className: 'addDriveBtn',
                    '&:hover': {
                      backgroundColor: button.bgColor,
                      color: button.textColor,
                      borderRadius: button.borderRadius,
                      border: `${button.border ? button.border : '1px solid'} ${button.borderColor}`,
                      fontWeight: button.fontWeight,
                      justifyContent: 'center',
                    },
                  }}
                  onClick={button.onClick}
                >
                  <span className="inline-block align-middle pr-1 font-semibold">{button.icon}</span>
                  <span className="break-keep xxs:text-[11px] leading-[14px]">{button.name}</span>
                </Button>
              ))}
            </div>
            <section className="relative pt-4 bg-blue-0">
              <div className="px-4 sm280:px-0">
                <CustomCalendar
                  data={data?.incomeHistoryDayList || []}
                  selectedDate={selectedDate}
                  onClickDate={onClickDate}
                  onClickMonth={updateSelectedDate}
                ></CustomCalendar>
              </div>

              <div className="px-4">
                <div className="rounded-lg w-full bg-white shadow-md p-3 my-3 relative min-h-[48px] h-fit">
                  <div
                    className="text-gray-8 flex items-center justify-between"
                    onClick={() => onClickExpandIcon('income')}
                  >
                    <div>
                      <span className="font-bold text-primary break-keep leading-[20px] text-lg align-middle">
                        {month}월 수익{' '}
                        {data?.incomeHistoryMonth?.income ? formatNumber(String(data.incomeHistoryMonth.income)) : 0}원
                      </span>
                      <TooltipComponent
                        title="<span style='font-weight: 500'>* 수익 = 총매출 - 지출 + 유가보조금</span><br/>입력하신 내용을 바탕으로 계산된 금액입니다.<br/>연비(거리), 부가세 등으로 차이가 발생할 수 있습니다."
                        content=""
                        alignmentTipbox="left"
                      ></TooltipComponent>
                    </div>
                    <div className="cursor-pointer">
                      {isExpanded.income ? (
                        <ChevronUpIcon color={colors.gray[6]} />
                      ) : (
                        <ChevronDownIcon color={colors.gray[6]} />
                      )}
                    </div>
                  </div>
                  {isExpanded.income && (
                    <div className="border-t border-gray-4 mt-2">
                      <dl className="flex justify-between pt-2 pb-1">
                        <dt className="text-gray-7">매출</dt>
                        <dd className="text-gray-8">
                          {data?.incomeHistoryMonth?.sales ? formatNumber(String(data.incomeHistoryMonth.sales)) : 0}원
                        </dd>
                      </dl>
                      <dl className="flex justify-between py-1">
                        <dt className="text-gray-7 align-middle">
                          <span className="align-middle">지출</span>
                          <TooltipComponent
                            title="주유비, 수리비, 통행료, 고정비용(지입료, 보험료, 캐피탈 이자)을 합산한 금액입니다."
                            content=""
                            alignmentTipbox="left"
                          ></TooltipComponent>
                        </dt>
                        <dd className="text-gray-8">
                          {data?.incomeHistoryMonth?.expense
                            ? formatNumber(String(data.incomeHistoryMonth.expense))
                            : 0}
                          원
                        </dd>
                      </dl>
                      <div className="flex flex-col justify-between bg-gray-1 p-3 rounded-lg">
                        <dl className="flex justify-between py-1">
                          <dt className="text-gray-7">고정비</dt>
                          <dd className="text-gray-8">
                            {data?.incomeHistoryMonth?.fixedCost
                              ? formatNumber(String(data.incomeHistoryMonth.fixedCost))
                              : 0}
                            원
                          </dd>
                        </dl>
                        <dl className="flex justify-between py-1">
                          <dt className="text-gray-7">주유비</dt>
                          <dd className="text-gray-8">
                            {data?.incomeHistoryMonth?.fuelCost
                              ? formatNumber(String(data.incomeHistoryMonth.fuelCost))
                              : 0}
                            원
                          </dd>
                        </dl>
                        <dl className="flex justify-between py-1">
                          <dt className="text-gray-7">수리비</dt>
                          <dd className="text-gray-8">
                            {data?.incomeHistoryMonth?.repairCost
                              ? formatNumber(String(data.incomeHistoryMonth.repairCost))
                              : 0}
                            원
                          </dd>
                        </dl>
                        <dl className="flex justify-between py-1">
                          <dt className="text-gray-7">통행료</dt>
                          <dd className="text-gray-8">
                            {data?.incomeHistoryMonth?.toll ? formatNumber(String(data.incomeHistoryMonth.toll)) : 0}원
                          </dd>
                        </dl>
                      </div>
                      <dl className="flex justify-between pt-1">
                        <dt className="text-gray-7">
                          <span className="align-middle">유가 보조금</span>
                          <TooltipComponent
                            title="[화물자동차 유가보조금 관리 규정]에 따라 유류세 연동 보조금을 자동으로 산출한 결과입니다. (유가연동보조금 제외)"
                            content=""
                            alignmentTipbox="left"
                          ></TooltipComponent>
                        </dt>
                        <dd className="text-gray-8">
                          {data?.incomeHistoryMonth?.subsidyForFuel
                            ? formatNumber(String(data.incomeHistoryMonth.subsidyForFuel))
                            : 0}
                          원
                        </dd>
                      </dl>
                    </div>
                  )}
                </div>

                <div
                  className="rounded-lg w-full bg-white shadow-md p-3 my-3 cursor-pointer min-h-[48px] h-fit"
                  onClick={onClickGoToOutstandingAmountList}
                >
                  <div className="text-gray-8 flex items-center justify-between">
                    <div className="inline-block">
                      <span className="font-bold text-gray-8 break-keep leading-[20px] text-lg align-middle">
                        {month}월 미수금{' '}
                        {data?.incomeHistoryMonth?.outstandingAmount
                          ? formatNumber(String(data.incomeHistoryMonth.outstandingAmount))
                          : 0}
                        원
                      </span>
                      <TooltipComponent
                        title="당월 매출 내역에서 수금 체크가 되어 있지 않은 항목을 합산한 금액입니다."
                        content=""
                        alignmentTipbox="left"
                      ></TooltipComponent>
                    </div>
                    <ChevronRightIcon color={colors.gray[6]} />
                  </div>
                </div>

                <div className="sm280:flex-col flex gap-3 pb-8">
                  <div
                    className="relative sm280:flex rounded-lg w-full bg-white shadow-md p-3 cursor-pointer"
                    onClick={() => onClickGoToManagement('fuel')}
                  >
                    <div className="text-gray-8 flex items-center justify-between">
                      <p className="text-gray-7">월 주유비</p>
                      <div className="absolute right-3 top-3.5">
                        <ChevronRightIcon color={colors.gray[6]} />
                      </div>
                    </div>
                    <p className="font-bold text-gray-8 overflow-hidden break-keep whitespace-no-wrap overflow-ellipsis max-w-[120px] sm280:ml-1">
                      {data?.incomeHistoryMonth?.fuelCost ? formatNumber(String(data.incomeHistoryMonth.fuelCost)) : 0}
                      원
                    </p>
                  </div>

                  <div
                    className="relative sm280:flex rounded-lg w-full bg-white shadow-md p-3 cursor-pointer"
                    onClick={() => onClickGoToManagement('repair')}
                  >
                    <div className="text-gray-8 flex items-center justify-between">
                      <p className="text-gray-7">월 수리비</p>
                      <div className="absolute right-3 top-3.5">
                        <ChevronRightIcon color={colors.gray[6]} />
                      </div>
                    </div>
                    <p className="font-bold text-gray-8 overflow-hidden break-keep whitespace-no-wrap overflow-ellipsis max-w-[120px] sm280:ml-1">
                      {data?.incomeHistoryMonth?.repairCost
                        ? formatNumber(String(data.incomeHistoryMonth.repairCost))
                        : 0}
                      원
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <BasicPopup
            isShow={isShow}
            title=""
            textContent="운행일지를 이용하기 위해<br/>차량 정보를 등록해주세요."
            textLeftBtn="취소"
            onClickLeftBtn={() => setIsShow(false)}
            textRightBtn="등록하기"
            onClickRightBtn={() => onClickGoToSetting()}
          ></BasicPopup>
          {isShowPopup && driveVehicleInfoId && (
            <DownToUpDetailPopup
              isShow={isShowPopup && !!driveVehicleInfoId}
              onClosePopup={onClosePopup}
              title={`${getFormatDate(dayjs(selectedDate).format('YYYY-MM-DD')?.toString(), ['month', 'day'])} 내역`}
              isBackdrop={false}
            >
              <DriveListView
                selectedDate={dayjs(selectedDate).format('YYYY-MM-DD')?.toString()}
                driveVehicleInfoId={driveVehicleInfoId}
                data={data}
                driveHistoryList={data?.driveHistoryList || []}
                refetch={refetch}
                onClickItem={onClickDetail}
                onClickAddBtn={onClickAddBtn}
              ></DriveListView>
            </DownToUpDetailPopup>
          )}
          {isShowDriveFormPopup && driveVehicleInfoId && (
            <DownToUpDetailPopup
              isShow={isShowDriveFormPopup && !!driveVehicleInfoId}
              onClosePopup={onCloseFormPopup}
              title={popupInfo.name}
              onClear={clearSelectedItem}
              isHeightFull
              isBackdrop={false}
            >
              <>
                {popupInfo.type == 'drive' && (
                  <DriveForm
                    selectedDate={selectedDate}
                    driveVehicleInfoId={driveVehicleInfoId}
                    data={clickedItemIndex !== null ? data?.driveHistoryList[clickedItemIndex] : undefined}
                    onClosePopup={onCloseFormPopup}
                    refetch={refetch}
                    onControlTutorial={onClickLastTutorial}
                    updateSelectedDate={updateSelectedDate}
                  ></DriveForm>
                )}
                {popupInfo.type == 'fuel' && (
                  <FuelingHistoryForm
                    onClosePopup={onCloseFormPopup}
                    selectedDate={selectedDate}
                    driveVehicleInfoId={driveVehicleInfoId}
                    data={clickedItemIndex !== null ? data?.fuelingHistories[clickedItemIndex] : undefined}
                    refetch={refetch}
                  ></FuelingHistoryForm>
                )}
                {popupInfo.type == 'repair' && (
                  <RepairHistoryForm
                    onClosePopup={onCloseFormPopup}
                    selectedDate={selectedDate}
                    driveVehicleInfoId={driveVehicleInfoId}
                    data={clickedItemIndex !== null ? data?.repairHistoryResponse : undefined}
                    refetch={refetch}
                  ></RepairHistoryForm>
                )}
              </>
            </DownToUpDetailPopup>
          )}
          {(isShowPopup || isShowDriveFormPopup) && (
            <div
              className={`fixed bg-black opacity-75 w-full h-full justify-center items-center top-0 left-0 inline-flex z-40`}
            ></div>
          )}
          <Tour
            steps={steps}
            isOpen={isTourOpen}
            onRequestClose={() => {
              if (step === 2) {
                onClickLastTutorial();
              }
            }}
            goToStep={step}
            closeWithMask={step === 2 ? true : false}
            rounded={10}
            showButtons={false}
            showNavigationNumber={false}
            showNavigation={false}
            showCloseButton={false}
          ></Tour>
          <HomePopup
            isShow={isShowFinalStep}
            image={popupImage}
            isShowBottomBtn={false}
            onClickCloseBtn={onClickFinalGuidePopup}
          ></HomePopup>
        </>
      )}
    </>
  );
};

export default DriveHome;
