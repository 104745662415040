import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RoundBadge from '@/components/Common/Badge/RoundBadge';
import BasicButton from '@/components/Common/Button/BasicButton';
import DualFooterButton from '@/components/Common/Button/DualFooterButton';
import SquareButton from '@/components/Common/Button/SquareButton';
import CarPriceTrendInfo from '@/components/Common/CarPriceTrendInfo';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import DownToUpDetailPopup from '@/components/Common/Popup/DownToUpDetailPopup';
import MenuHeader from '@/components/Header/MenuHeader';
import { HandShakeIcon, SpeedIcon } from '@/components/Icon';
import SimilarProductList from '@/components/Products/SimilarProductList';
import { colors } from '@/const/colors';
import { PRODUCT_TYPE_DIRECT, PRODUCT_TYPE_SPEED } from '@/const/products';
import { PRODUCT_SALE_URL } from '@/const/url';
import useUpdateProductFormMutation from '@/hooks/query/useUpdateProductFormMutation';
import useBlockNavigation from '@/hooks/useBlockNavigation';
import useFetchProductData from '@/hooks/useFetchProductData';
import { productsFormAtom } from '@/store/products';
import { getFormatDateOnlyNum } from '@/utils/common';

const PriceTrendResultPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [productFormData] = useAtom(productsFormAtom);
  const setProductFormData = useSetAtom(productsFormAtom);
  const [prevData, setPrevData] = useState<ProductDetailResponse | null>(null);

  const [isSelectedLeft, setIsSelectedLeft] = useState(false);
  const [isSelectedRight, setIsSelectedRight] = useState(false);

  const [isShow, setIsShow] = useState(false);
  const [isShowConfirmPopup, setIsShowConfirmPopup] = useState(false);

  const [isShowExitConfirmPopup, setIsShowExitConfirmPopup] = useState(false);

  const productSearchParams = useFetchProductData(id, true);

  useEffect(() => {
    setPrevData(productFormData);
  }, []);

  useBlockNavigation(setIsShowExitConfirmPopup);

  const updateProductTemporarySaveMutation = useUpdateProductFormMutation({
    url: PRODUCT_SALE_URL,
  });

  const hasTypeChanged = () => prevData?.type?.code !== selectedType?.code;

  const handleClickExitBtn = () => {
    setIsShowExitConfirmPopup(false);
    if (hasTypeChanged()) {
      updateProductTemporarySaveMutation.mutate({ id: Number(productFormData?.id), type: selectedType?.code });
    } else {
      navigate(PRODUCT_SALE_URL, { replace: true });
    }
  };

  const handleCloseExitPopup = () => {
    setIsShowExitConfirmPopup(false);
  };

  const updateProductNextStepMutation = useUpdateProductFormMutation({
    url: `/products/sales/additional-info/${id}`,
  });

  const handleNavigation = () => {
    setProductFormData({ ...productFormData, type: selectedType } as ProductDetailResponse);
    if (hasTypeChanged()) {
      updateProductNextStepMutation.mutate({ id: Number(productFormData?.id), type: selectedType?.code });
    } else {
      navigate(`/products/sales/additional-info/${id}`, { replace: true });
    }
  };

  const handleCloseConfirmPopup = () => {
    setIsShowConfirmPopup(false);
  };

  const handleClickPrev = () => {
    navigate(`/products/sales/axis/${id}`, { replace: true });
  };

  const handleClickNext = () => {
    if (selectedType?.code === PRODUCT_TYPE_SPEED) {
      setIsShowConfirmPopup(true);
    } else {
      handleNavigation();
    }
  };

  const [selectedType, setSelectedType] = useState(productFormData.type);

  const onSelectType = (type: EnumPresenter) => {
    setSelectedType(type);
    setIsSelectedLeft(type.code === PRODUCT_TYPE_SPEED);
    setIsSelectedRight(type.code === PRODUCT_TYPE_DIRECT);
  };

  const handleClickSpeedType = () => onSelectType({ code: PRODUCT_TYPE_SPEED, desc: '매입 견적' });
  const handleClickDirectType = () => onSelectType({ code: PRODUCT_TYPE_DIRECT, desc: '직거래' });

  const handleClear = () => {
    setSelectedType({ code: '', desc: '' });
    setIsSelectedRight(false);
    setIsSelectedLeft(false);
  };

  return (
    <>
      <MenuHeader title="내차판매" />
      <div className="flex flex-col w-full p-4 pb-24 pt-[60px]">
        <div className="pb-24 pt-6">
          <div className="flex justify-between">
            <h3 className="text-2xl font-bold break-keep">판매 방식을 선택해주세요.</h3>
            <RoundBadge text="5/9" />
          </div>
          <div className="rounded-lg bg-blue-0 p-4 mt-6">
            <p className="text-lg font-bold mb-2 text-gray-8">
              {getFormatDateOnlyNum(productFormData?.firstRegistrationDate, ['year'])}년{' '}
              {productFormData?.manufacturerCategories?.name} {productFormData?.model?.name} {productFormData?.tons}톤{' '}
              {productFormData?.axis?.desc} {productFormData?.loaded?.desc}
            </p>
            <span className="text-gray-8 text-sm">해당 차종의 평균 시세 정보를 불러왔어요.</span>
          </div>
          <div className="mt-3">
            <p className="text-xs text-gray-6">
              * 차량의 주행거리, 차량의 상태, 옵션등에 따라 시세가 변동될 수 있습니다.
            </p>
            <CarPriceTrendInfo
              priceSearchParams={productSearchParams}
              showPriceComparison={false}
              isSearchPage={false}
              apiType="private"
            />
            <SimilarProductList similarSearchParams={productSearchParams} />
          </div>

          <DualFooterButton
            leftButtonText="이전"
            onClickLeftButton={handleClickPrev}
            rightButtonText="판매 방식 선택"
            onClickRightButton={() => setIsShow(true)}
          />
        </div>
      </div>
      <DownToUpDetailPopup
        isShow={isShow}
        onClosePopup={() => setIsShow(false)}
        title={'판매 방식'}
        onClear={handleClear}
      >
        <div className="flex pb-[84px] pt-3 px-2">
          <SquareButton
            title="직트럭에 판매"
            description="직트럭과 상담 후 빠르게 판매하기"
            bgColor={colors.blue[0]}
            textColor={colors.primary}
            icon={<SpeedIcon color={colors.primary} width={67} height={32} />}
            minWidth={60}
            minHeight={160}
            onClick={handleClickSpeedType}
            isSelected={isSelectedLeft}
          />
          <SquareButton
            title="직거래 판매"
            description="일반 구매자와 직거래하기"
            bgColor={colors.blue[0]}
            textColor={colors.primary}
            icon={<HandShakeIcon color={colors.primary} width={32} height={32} />}
            minWidth={60}
            minHeight={160}
            onClick={handleClickDirectType}
            isSelected={isSelectedRight}
          />
        </div>
        <div className="flex items-center justify-center fixed w-full px-3 pb-4 pt-0 mx-auto my-0 bottom-0 left-0 right-0 max-w-[720px] min-w-[280px] bg-white">
          <BasicButton
            name="추가 정보 입력하기"
            bgColor={colors.primary}
            borderColor={colors.primary}
            textColor={colors.gray[0]}
            fontSize={16}
            fontWeight="bold"
            isDisabled={!isSelectedLeft && !isSelectedRight}
            onClick={handleClickNext}
          />
        </div>
      </DownToUpDetailPopup>

      <BasicPopup
        isShow={isShowConfirmPopup}
        title="직트럭에 판매하기"
        textContent={'차량을 직트럭에 판매하시겠어요?<br/>추가 정보 입력 후 직트럭 담당자가<br/>연락 드릴 예정입니다.'}
        textLeftBtn="취소"
        onClickLeftBtn={handleCloseConfirmPopup}
        textRightBtn="정보 입력"
        onClickRightBtn={handleNavigation}
      />
      <BasicPopup
        isShow={isShowExitConfirmPopup}
        title="정말 나가시겠어요?"
        textContent={'작성 중인 내용은 임시저장되며<br/>언제든지 재등록이 가능합니다.'}
        textLeftBtn="취소"
        onClickLeftBtn={handleCloseExitPopup}
        textRightBtn="나가기"
        onClickRightBtn={handleClickExitBtn}
      />
    </>
  );
};

export default PriceTrendResultPage;
