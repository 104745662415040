import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate, useSearchParams } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';

export default function LoginHandler() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const deviceToken = localStorage.getItem('deviceToken');
  const [cookies, setCookie, removeCookie] = useCookies(['redirect_url']);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);

      if (deviceToken) {
        apiManager
          .post('/api/v1/fire-base/device-token', { deviceToken: deviceToken })
          .then((response) => {})
          .catch((error) => {});
      }

      if (cookies && cookies.redirect_url) {
        navigate(cookies.redirect_url);
        removeCookie('redirect_url');
      } else {
        navigate('/');
      }
    } else {
      navigate('/');
    }
  }, []);

  return <></>;
}
