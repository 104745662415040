import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PaperAirplaneIcon, PlusSmallIcon } from '../Icon';
import ChatInputComponent from './ChatInputComponent';
import { colors } from '@/const/colors';

type ChatRoomDataProps = {
  scrollBottom: () => void;
  handleInputHeightChange: (Height: number) => void;
  chatInput: string;
  updateChatInput: (data: string) => void;
  sendChat: () => void;
  imageList: File[];
  updateImageList: (data: File[]) => void;
};

const ChatRoomInput = ({
  handleInputHeightChange,
  chatInput,
  updateChatInput,
  sendChat,
  imageList,
  updateImageList,
}: ChatRoomDataProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const inputRef = useRef(null);
  const [sending, setSending] = useState(false);
  const navigate = useNavigate();

  const onChangeChat = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    updateChatInput(e.target.value);
  }, []);

  const openFileExplorer = () => {
    if (fileInputRef.current) {
      (fileInputRef.current as HTMLInputElement).click();
    }
  };

  const handleSendButtonClick = async () => {
    if (sending || !chatInput.trim()) {
      return;
    }
    setSending(true);
    try {
      sendChat();
    } catch (error) {
      console.error('Error sending chat:', error);
    } finally {
      setSending(false);
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const filesArray = Array.from(selectedFiles); // FileList를 File[]로 변환
      updateImageList(filesArray);
    }
  };

  useEffect(() => {
    if (imageList.length > 0) {
      sendChat();
    }
  }, [imageList]);

  useEffect(() => {
    if (inputRef.current) {
      const observer = new ResizeObserver(() => {
        if (inputRef.current) {
          handleInputHeightChange((inputRef.current as HTMLDivElement).clientHeight);
        }
      });
      observer.observe(inputRef.current, {});

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <>
      <div
        ref={inputRef}
        className="shrink-0 z-30 fixed bottom-0 left-0 w-full p-2.5 bg-white flex items-center justify-between shadow-[0_-2px_4px_rgba(0,0,0,0.05)] mx-auto left-0 right-0 my-0 max-w-[720px] min-w-[280px] max-h-[700px]"
      >
        <div className="flex rounded-lg w-6 h-6 mx-[10px] justify-center items-center text-center">
          <div
            className="flex justify-center items-center w-5 h-5 cursor-pointer border border-gray-7 rounded-[6px]"
            onClick={openFileExplorer}
          >
            <PlusSmallIcon color={colors.gray[7]} width={24} height={24}></PlusSmallIcon>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept="image/*"
            multiple
          />
        </div>
        <ChatInputComponent
          name="message"
          placeholder="메시지 보내기"
          value={chatInput}
          fontSize={18}
          minRows={1}
          maxRows={4}
          onChange={onChangeChat}
        ></ChatInputComponent>
        <div className={`ml-4 ${chatInput.trim() ? 'cursor-pointer' : ''}`} onClick={handleSendButtonClick}>
          <PaperAirplaneIcon color={chatInput.trim() ? colors.gray[8] : colors.gray[5]}></PaperAirplaneIcon>
        </div>
      </div>
    </>
  );
};

export default ChatRoomInput;
