import React from 'react';

interface ProductOriginProps {
  productOriginData: ProductDetailResponse | undefined;
}

const TransactionHistory: React.FC<ProductOriginProps> = ({ productOriginData }) => {
  return (
    <>
      <div className="py-4 my-2 px-4 text-gray-8">
        {!productOriginData && <p className="text-sm">원부데이터 조회에 실패했습니다.</p>}
        <div className="text-base bg-gray-1 rounded-lg p-4" id="TRADING">
          {productOriginData?.tradingHistory && productOriginData?.tradingHistory.length > 0 ? (
            productOriginData?.tradingHistory?.map((item, index) => (
              <dl key={index}>
                <dt className={`font-semibold mb-1 ${index === 0 ? '' : 'border-t border-gray-3 pt-5 mt-5'}`}>
                  {item.date}
                </dt>
                <dd className="mt-2">{item.content}</dd>
              </dl>
            ))
          ) : (
            <p className="text-gray-6">거래 이력 없음</p>
          )}
        </div>
      </div>
    </>
  );
};

export default TransactionHistory;
