import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import { fetchMyProducts } from '@/api/products/getProducts';
import DraftListingBox from '@/components/Common/DraftListingBox';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import MyProductListSkeleton from '@/components/Common/Skeleton/MyProductListSkeleton';
import MenuHeader from '@/components/Header/MenuHeader';
import { EmptyTruckIcon } from '@/components/Icon';
import MoreMenu from '@/components/Menu/MoreMenu';
import ProductStatus from '@/components/More/ProductStatus';
import ProductType from '@/components/More/ProductType';
import ProductTypeChangeButton from '@/components/More/ProductTypeChangeButton';
import MyProductPrice from '@/components/Products/MyProductPrice';
import { colors } from '@/const/colors';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { BEFORE_SALE, ORIGIN_DATA_REGISTER } from '@/const/productStatus';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useResetProductsForm } from '@/store/products';

const MyProducts = () => {
  const navigate = useNavigate();
  const resetProductsForm = useResetProductsForm();
  const [myProducts, setMyProducts] = useState<ProductDetailResponse[]>([]);
  const [menuStates, setMenuStates] = useState<boolean[]>([]);
  const [isNone, setIsNone] = useState<boolean>(false);
  const { showToast } = useToastContext();
  const deleteId = useRef<number>();

  const [isShowPopup, setIsShowPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState<string | undefined>('');

  const toggleMenu = (index: number) => {
    const newMenuStates = [...menuStates];
    for (let i = 0; i < newMenuStates.length; i++) {
      if (i !== index) {
        newMenuStates[i] = false;
      }
    }
    newMenuStates[index] = !newMenuStates[index];
    setMenuStates(newMenuStates);
  };

  const { data, isFetching, isLoading } = useQuery(['getMyProducts'], () => fetchMyProducts(), {
    onSuccess: (responseData) => {
      setMyProducts(responseData);
      if (responseData.length === 0) {
        setIsNone(true);
      }
      setMenuStates(responseData.map(() => false));
    },
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'success', 'bottom');
      setIsNone(true);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const fetchDetail = (product: ProductDetailResponse) => {
    if (product.status.code !== ORIGIN_DATA_REGISTER && product.status.code !== BEFORE_SALE) {
      navigate('/products/' + product.id, { state: { isPublicDetail: false } });
    }
  };

  useEffect(() => {
    setIsNone(myProducts?.length === 0);
  }, [myProducts]);

  const onClickDelete = (item: ProductDetailResponse) => {
    if (item) {
      setIsShowPopup(true);
      setPopupTitle(item.truckNumber);
      deleteId.current = item.id;
    }
  };

  const deleteProduct = () => {
    apiManager
      .delete(`/api/v1/products/${deleteId.current}`)
      .then((response) => {
        if (response) {
          setMyProducts((prev) => prev.filter((item) => item.id !== deleteId.current));
        }
        showToast('차량이 삭제되었어요.', 'success', 'bottom');
        setIsShowPopup(false);
      })
      .catch((error) => {
        showToast('문제가 생겼어요. 다시 시도해주세요', 'error', 'bottom');
      });
  };

  const isShowDivider =
    myProducts.some((item) => item.status.code === 'BEFORE_SALE') &&
    myProducts.some((item) => item.status.code !== 'BEFORE_SALE');

  const onClickMore = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  useEffect(() => {
    resetProductsForm();
  }, []);

  return (
    <>
      <MenuHeader title="내차 관리"></MenuHeader>
      <div className="w-full pt-[60px] min-h-[calc(100vh-60px)] pb-4">
        {isFetching || isLoading ? (
          <MyProductListSkeleton></MyProductListSkeleton>
        ) : isNone ? (
          <div className="flex px-4 flex-col justify-center items-center w-full h-full">
            <EmptyTruckIcon color={colors.gray[4]} width={120} height={120}></EmptyTruckIcon>
            <span className="text-gray-7 text-lg mt-[40px]">차량 내역이 없습니다.</span>
          </div>
        ) : (
          <section className="flex">
            <ul className="grid grid-cols-1 gap-2 w-full">
              {myProducts && (
                <>
                  {myProducts.map((item) =>
                    item.status.code === 'BEFORE_SALE' ? (
                      <div key={item.id} className="px-4">
                        <DraftListingBox data={item} onClickDelete={onClickDelete} updateList={setMyProducts} />
                      </div>
                    ) : null,
                  )}
                </>
              )}

              {isShowDivider && <div className="h-2 w-full bg-gray-1"></div>}

              {myProducts.map((item, index) =>
                item.status.code !== 'BEFORE_SALE' ? (
                  <div key={item.id} className="px-4">
                    <li
                      className={`relative flex justify-between items-center py-4 ${
                        index !== 0 ? 'border-t border-gray-4' : ''
                      }`}
                    >
                      <div className="flex justify-between flex-1" onClick={() => fetchDetail(item)}>
                        <div className="h-[100px] w-[100px] min-w-[80px] min-h-[80px]">
                          <img
                            className="rounded-lg w-full h-full object-cover bg-cover"
                            src={
                              item.productsImage?.frontSideImageUrl
                                ? item.productsImage.frontSideImageUrl
                                : 'https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/car_none.png'
                            }
                            alt="트럭 사진"
                          />
                        </div>
                        <div className="flex-1 ml-3 min-w-[140px]">
                          <div className="flex flex-wrap gap-[6px]">
                            <ProductStatus status={item.status}></ProductStatus>
                            <ProductType
                              type={item.type?.code === 'SPEED' ? item.statusOfSpeedProduct : item.type}
                            ></ProductType>
                          </div>

                          <dd className="text-base font-medium pt-2 text-gray-8">{item.truckNumber}</dd>
                          <dd className="flex items-center">
                            <span className="font-semibold text-gray-9 text-lg">
                              <MyProductPrice product={item}></MyProductPrice>
                            </span>
                          </dd>
                          <ProductTypeChangeButton
                            id={item.id}
                            type={item.type}
                            status={item.status}
                            setProductList={setMyProducts}
                            statusOfSpeedProduct={item.statusOfSpeedProduct}
                          ></ProductTypeChangeButton>
                        </div>
                        <div className="absolute right-2 top-[60px]" onClick={onClickMore}>
                          <MoreMenu
                            id={item.id}
                            status={item.status.code}
                            price={Number(item.price)}
                            setProductList={setMyProducts}
                            confirmTitle={item.truckNumber}
                            isShow={menuStates[index]}
                            onToggleMenu={() => toggleMenu(index)}
                          ></MoreMenu>
                        </div>
                      </div>
                    </li>
                  </div>
                ) : null,
              )}
            </ul>
          </section>
        )}
      </div>
      <BasicPopup
        isShow={isShowPopup}
        title={popupTitle}
        textContent="차량을 정말 삭제하시겠어요?"
        textLeftBtn="취소"
        onClickLeftBtn={() => setIsShowPopup(false)}
        textRightBtn="삭제"
        onClickRightBtn={() => deleteProduct()}
      ></BasicPopup>
    </>
  );
};

export default MyProducts;
