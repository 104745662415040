export const colors = {
  primary: '#1E42A6',
  red: '#F5222D',
  yellow: '#FFF065',
  kakao: '#FFE402',
  naver: '#03C75A',
  green: '#34A853',
  blue: {
    0: '#F8FAFF',
    1: '#F3F7FF',
    2: '#D1DDFF',
    3: '#5578F0',
    4: '#1976D2',
    5: '#E7EFFF',
    6: '#F0F2F5',
    7: '#335CCF',
    8: '#3C5FBF',
    9: '#353C51',
    10: '#E2E9F9',
  },
  gray: {
    0: '#ffffff',
    1: '#FAFAFA',
    2: '#F5F5F5',
    3: '#E8E8E8',
    4: '#DCDCDC',
    5: '#BEBEBE',
    6: '#919191',
    7: '#737373',
    8: '#414141',
    9: '#121212',
    10: '#000000',
  },
};
