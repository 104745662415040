import React from 'react';

import '@/css/bubbleStyle.css';

interface BubbleComponentProps {
  text: string;
  bgColor: string;
  shadow?: boolean;
  padding?: string;
  borderRadius?: string;
}

const BubbleComponent = ({
  text,
  bgColor,
  padding = '10px 11px',
  shadow = false,
  borderRadius = '1em',
}: BubbleComponentProps) => {
  return (
    <>
      <div
        className={`speech-bubble ${shadow ? 'shadow-[0_-2px_4px_rgba(0,0,0,0.05)]' : ''}`}
        style={
          {
            '--bubble-bg-color': bgColor,
            '--bubble-padding': padding,
            '--bubble-border-radius': borderRadius,

            filter: shadow ? 'drop-shadow(10px 6px 6px rgba(65, 65, 65, 0.3))' : 'none',
          } as React.CSSProperties
        }
      >
        <p dangerouslySetInnerHTML={{ __html: text }}></p>
      </div>
    </>
  );
};

export default BubbleComponent;
