import React, { ReactNode, useEffect, useState } from 'react';

import { XMarkIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import '@/css/downToUpDetailPopup.css';

interface SelectPopupInterface {
  isShow: boolean;
  children?: ReactNode;
  onClosePopup: (isShow: boolean) => void;
  title?: string;
  onClear?: () => void;
  isHeightFull?: boolean;
  heightRate?: number;
  isBackdrop?: boolean;
  isShowXMark?: boolean;
}

const DownToUpDetailPopup = ({
  isShow,
  children,
  onClosePopup,
  title,
  onClear,
  isHeightFull,
  heightRate = 100,
  isBackdrop = true,
  isShowXMark = true,
}: SelectPopupInterface) => {
  const [showPopup, setShowPopup] = useState(true);

  const onClickClose = () => {
    setShowPopup(false);

    setTimeout(() => {
      onClosePopup(false);
    }, 200);

    if (onClear) {
      onClear();
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    setShowPopup(isShow);
  }, [isShow]);

  useEffect(() => {
    document.body.style.overflow = showPopup ? 'hidden' : 'auto';
  }, [showPopup]);

  return (
    <>
      {isShow && (
        <>
          {isBackdrop && (
            <div
              className={`fixed bg-black opacity-75 w-full h-full justify-center items-center top-0 left-0 inline-flex z-40`}
            ></div>
          )}

          <div
            className={`${
              showPopup ? 'fadeInUpObj' : 'fadeOutObj'
            } fixed z-50 bottom-0 left-0 w-full rounded-t-xl bg-white flex flex-col items-center justify-center shadow-[0_-2px_4px_rgba(0,0,0,0.05)] mx-auto left-0 right-0 my-0 max-w-[720px] min-w-[280px]`}
            style={{
              maxHeight: `calc(${heightRate}dvh - 60px)`,
            }}
          >
            <div
              className={`w-full rounded-t-xl max-w-[720px] min-w-[280px] ${
                isHeightFull ? 'h-[calc(100dvh-60px)]' : ''
              }`}
              style={{
                maxHeight: `calc(${heightRate}dvh - 60px)`,
              }}
            >
              <div className="sticky py-4">
                {isShowXMark && (
                  <div className="absolute m-4 cursor-pointer top-0 right-0" onClick={onClickClose}>
                    <XMarkIcon color={colors.gray[9]}></XMarkIcon>
                  </div>
                )}

                <h4 className="font-bold text-center text-gray-8">{title}</h4>
              </div>
              <div
                className={`overflow-y-auto`}
                style={{
                  maxHeight: `calc(${heightRate}dvh - 112px)`,
                }}
              >
                {children}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DownToUpDetailPopup;
