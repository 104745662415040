import { useMemo } from 'react';
import { QueryFunctionContext, useInfiniteQuery } from 'react-query';

interface useFetchNoticeListQueryProps {
  rowsPerPage: number;
  queryFn: (context: QueryFunctionContext<any>) => Promise<any>;
}

const useFetchNoticeListQuery = ({ rowsPerPage, queryFn }: useFetchNoticeListQueryProps) => {
  const queryKey = ['get-notice-list'];
  const { data, isLoading, isFetching, isError, hasNextPage, fetchNextPage, isFetchingNextPage, refetch } =
    useInfiniteQuery<any>(queryKey, queryFn, {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage?.data.length === 0 || lastPage?.data.length < rowsPerPage ? undefined : nextPage;
      },
      cacheTime: 1000 * 60,
      staleTime: 1000 * 30,
    });

  const noticeList = useMemo(() => {
    return data?.pages.flatMap((page) => page?.data);
  }, [data]);

  return {
    noticeList,
    isLoading,
    isFetching,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  };
};

export default useFetchNoticeListQuery;
