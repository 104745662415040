import React, { SVGProps } from 'react';

const SvgEmptySpeakerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 120 120" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="m39 67.181 34 12.953v-54.13L39 40.576V67.18Zm0 4.281 32.576 12.41c2.618.997 5.424-.936 5.424-3.738v-54.13c0-2.871-2.936-4.808-5.576-3.676L35 37.938H24a9 9 0 0 0-9 9v14c0 4.633 3.5 8.448 8 8.945v23.055a5 5 0 0 0 5 5h6a5 5 0 0 0 5-5V71.462Zm-15-5.524h11v-24H24a5 5 0 0 0-5 5v14a5 5 0 0 0 5 5Zm3 4v23a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-23h-8Zm58.912 3.912a24 24 0 0 0 0-33.941l-3.394 3.394a19.2 19.2 0 0 1 0 27.153l3.394 3.394Zm15.599-2.962a36.14 36.14 0 0 1-7.835 11.727l-3.016-3.017a31.88 31.88 0 0 0 0-45.084l3.016-3.017a36.14 36.14 0 0 1 10.587 25.559c0 4.747-.935 9.447-2.752 13.832Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgEmptySpeakerIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgEmptySpeakerIcon;
