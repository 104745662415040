import React from 'react';

interface ProductOriginProps {
  productOriginData: ProductDetailResponse | undefined;
}
const SeizureMortgageHistory: React.FC<ProductOriginProps> = ({ productOriginData }) => {
  return (
    <>
      <div className="py-4 my-2 text-gray-8">
        {!productOriginData && <p className="text-sm">원부데이터 조회에 실패했습니다.</p>}
        <h4 className="text-gray-8 font-semibold text-[18px] mx-4 mb-3" id="SEIZURE">
          압류 이력
        </h4>

        <div className="p-4 bg-gray-1 rounded-lg px-4 mx-4">
          {productOriginData?.seizureHistory && productOriginData?.seizureHistory.length > 0 ? (
            productOriginData?.seizureHistory?.map((item, index) => (
              <dl key={index} className="text-base">
                <dt className={`font-semibold text-base ${index !== 0 ? 'border-t border-gray-3 mt-5 pt-5' : ''}`}>
                  {item.regDate}
                </dt>
                <dd className="text-gray-8">
                  <ul>
                    <li>・ 압류내역 : {item?.content}</li>
                    <li>・ 촉탁기관 : {item?.agency}</li>
                    {item.agencyPhoneNumber && <li>・ 전화번호 : {item?.agencyPhoneNumber}</li>}
                  </ul>
                </dd>
              </dl>
            ))
          ) : (
            <p className="text-gray-6">압류 이력 없음</p>
          )}
        </div>
        <div className="w-full h-[8px] bg-gray-1 my-[30px]"></div>
        <h4 className="text-gray-8 font-semibold text-[18px] mx-4 mb-3" id="MORTGAGE">
          저당 이력
        </h4>
        <div className="p-4 bg-gray-1 rounded-lg px-4 mx-4">
          {productOriginData?.mortgageHistory && productOriginData?.mortgageHistory.length > 0 ? (
            productOriginData.mortgageHistory.map((item, index) => (
              <div key={index} className={`text-base ${index !== 0 ? 'border-t border-gray-3 mt-5 pt-5' : ''}`}>
                <div>
                  <dt className={`font-semibold ${index === 0 ? '' : 'mt-5'}`}>{item.occurDate}</dt>
                  <dd className="text-gray-8">
                    <ul>
                      <li>・ 기관 : {item?.mortgageName}</li>
                      <li>・ 채무자 : (차주){item?.debtorName}</li>
                      <li>・ 채권가액 : {item?.amount}원</li>
                    </ul>
                  </dd>
                </div>
                <div
                  className={`${
                    index + 1 === productOriginData.mortgageHistory?.length
                  } text-gray-7 text-[12px] leading-[16px] pt-4`}
                >
                  * 차량 대금 입금 전, ‘조회 당일 기준’ 남아있는 완납금을 확인하시고 차량 대금보다 완납 금액이 높게
                  남아있을 경우 차액 금액은 판매자가 처리할 수 있어야 합니다.
                </div>
              </div>
            ))
          ) : (
            <div className="text-gray-6">저당 이력 없음</div>
          )}
        </div>
      </div>
    </>
  );
};

export default SeizureMortgageHistory;
