import React, { useEffect, useRef, useState } from 'react';

import TooltipBox from '../TooltipBox';
import { InformationCircleIcon } from '@/components/Icon';
import { colors } from '@/const/colors';

interface TooltipProps {
  title: string;
  content: string;
  maxWidth?: number;
  minWidth?: number;
  position?: string;
  alignmentTipbox?: 'center' | 'left';
  color?: string;
}

const TooltipComponent = ({
  title,
  content,
  minWidth = 250,
  maxWidth = 680,
  position = 'left',
  alignmentTipbox = 'center',
  color = `${colors.gray[5]}`,
}: TooltipProps) => {
  const [isShow, setIsShow] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null); // 툴팁 요소의 ref

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        setIsShow(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const onClickTooltip = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsShow(!isShow);
  };

  return (
    <div className="inline" onClick={(e) => onClickTooltip(e)} ref={tooltipRef}>
      <InformationCircleIcon color={color} style={{ display: 'inline' }}></InformationCircleIcon>
      {isShow && (
        <TooltipBox
          msg={title}
          descMsg={content}
          minWidth={minWidth}
          maxWidth={maxWidth}
          position={position}
        ></TooltipBox>
      )}
    </div>
  );
};

export default TooltipComponent;
