import React, { useState } from 'react';
import LazyLoad from 'react-lazyload';
import { useNavigate } from 'react-router-dom';

import FilledBadge from './Badge/FilledBadge';
import { SALES_TYPE_ASSURANCE, SALES_TYPE_THIRD_PARTY_DEALER } from '@/const/products';
import { asYYYYMM, formatDistanceToThousandKm, formatPrice } from '@/utils/common';
import '@egjs/react-flicking/dist/flicking.css';
import Skeleton from '@mui/material/Skeleton';

interface ProductCardProps {
  products: ProductsList;
  index: number;
}

export default function ProductCard({ products, index }: ProductCardProps) {
  const navigate = useNavigate();
  const [isImageLoading, setIsImageLoading] = useState(true);

  const fetchDetail = (id: number) => {
    navigate('/products/' + id, { state: { isPublicDetail: true } });
  };

  const isShowSalesType = (salesType: string) => {
    return salesType !== SALES_TYPE_ASSURANCE && salesType !== SALES_TYPE_THIRD_PARTY_DEALER;
  };

  return (
    <div
      onClick={() => {
        fetchDetail(products?.id);
      }}
    >
      <ul className="mt-4">
        {
          <li className={`p-1`}>
            <div className="flex w-full border border-gray-100 rounded-lg h-[15rem] relative">
              <LazyLoad className="w-full h-full" offset={100}>
                {isImageLoading && (
                  <div className={`flex w-full border border-gray-100 rounded-lg h-[15rem]`}>
                    <Skeleton variant="rounded" width="100%" height="100%" animation="wave" />
                  </div>
                )}
                <img
                  className={`w-full h-full rounded-lg object-cover  ${isImageLoading ? 'invisible' : 'block'}`}
                  src={products?.representImageUrl}
                  alt="트럭 사진"
                  style={{ maxWidth: '100%', maxHeight: '100', objectFit: 'cover' }}
                  onLoad={(e) => {
                    setIsImageLoading(false);
                  }}
                  width={100}
                  height={100}
                />
                {products?.salesType && isShowSalesType(products.salesType.code) && (
                  <div className="absolute top-0 left-0 p-3">
                    <FilledBadge
                      textColor="text-gray-0"
                      bgColor="primary"
                      rounded="rounded"
                      fontWeight="font-bold"
                      text={products?.salesType?.desc}
                    ></FilledBadge>
                  </div>
                )}
                <div className="absolute bottom-0 left-0 w-full h-[50px] p-3 rounded-b-lg bg-gradient-to-t from-[rgba(0,0,0,0.7)] to-[rgba(0,0,0,0)]">
                  <div className="absolute bottom-0 left-0 p-3 ">
                    <span className="text-gray-0">매물번호 {products?.productsNumber}</span>
                  </div>
                </div>
              </LazyLoad>
            </div>

            <dt className="font-semibold text-gray-8 text-xl mt-2 mb-1 flex items-center break-keep">
              <p className="flex-1">{products?.truckName}</p>
            </dt>
            <dd className="flex flex-wrap text-gray-6 mt-1 mb-2 line-clamp-2 text-[16px] leading-[20px]">
              <span>{asYYYYMM(products?.firstRegistrationDate)}</span>
              <span className="ml-1">• {products?.transmission.desc}</span>
              <span className="ml-1">• {formatDistanceToThousandKm(products?.distance)}만km</span>
              <span className="ml-1">• {products?.loadedInnerLength}m</span>
              <span className="ml-1">• {products?.power}마력</span>
              <span className="ml-1">{!!products.palletCount && `• 파렛트 ${products.palletCount}개`}</span>
            </dd>
            {products?.price && (
              <dd className="my-2 flex items-center">
                <span className="flex-1">
                  <span className="font-semibold text-[22px]">{formatPrice(products?.price?.toString())}</span>
                </span>
              </dd>
            )}
          </li>
        }
      </ul>
    </div>
  );
}
