import React from 'react';
import { Helmet } from 'react-helmet-async';

interface IProps {
  title: string;
  siteUrl: string;
  description?: string;
}

export function PageMetas({ title, siteUrl, description }: IProps): JSX.Element {
  return (
    <Helmet>
      <meta property="og:title" content={title} />
      <meta property="og:url" content={siteUrl} />
      {!!description ? <meta property="og:description" content={description} /> : null}
    </Helmet>
  );
}
