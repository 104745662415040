import React from 'react';

type ProductHistoryProps = {
  product?: ProductDetailResponse;
};

interface Data {
  key: string;
  title: string;
  value?: HistoryType | undefined;
  values?: HistoryType[] | undefined | null;
}

export default function ProductHistory({ product, ...props }: ProductHistoryProps) {
  const productHistory: Data[] = [
    { key: 'LAST_OWNER', title: '소유자 정보 (현물출자 이력)', value: product?.lastOwnerInfo },
    { key: 'SEIZURE', title: '압류 이력', values: product?.seizureHistory ? product?.seizureHistory : null },
    { key: 'MORTGAGE', title: '저당 이력', values: product?.mortgageHistory ? product?.mortgageHistory : null },
    { key: 'TRADING', title: '소유자 변경 이력', values: product?.tradingHistory },
    { key: 'INSPECTION', title: '검사 이력', values: product?.inspectionHistory },
    { key: 'TUNING', title: '구조 변경 이력', values: product?.tuningHistory },
  ];

  return (
    <div className="py-2 my-2 text-gray-8">
      {productHistory.map((history, index) => (
        <div key={index} className={`${index !== 0 && !!history.values ? 'border-t-8 border-gray-1' : ''}`}>
          <div className="px-4">
            {history.key === 'LAST_OWNER' && (
              <div className="pt-2 pb-6">
                <p className="font-semibold text-lg pb-3">{history.title}</p>
                {history.value ? (
                  <dl className="text-base bg-gray-1 rounded-lg p-4">
                    <dt className="font-semibold mb-1">{history.value.date}</dt>
                    <dd>{history.value.content}</dd>
                  </dl>
                ) : (
                  <div className="text-base bg-gray-1 text-gray-6 rounded-lg p-4">소유자 정보 없음</div>
                )}
              </div>
            )}

            <dl className="text-base">
              {history.key === 'SEIZURE' && !!history.values && (
                <div className="py-6">
                  <p className="text-lg font-semibold pb-3" id={history.key}>
                    {history.title}
                  </p>
                  <div className="p-4 bg-gray-1 rounded-lg">
                    {history.values && history.values.length > 0 ? (
                      history.values.map((item, index) => (
                        <div key={index} className={`text-base ${index !== 0 ? 'border-t border-gray-3 mt-5' : ''}`}>
                          <div>
                            <dt className={`font-semibold ${index === 0 ? '' : 'mt-5'}`}>{item.regDate}</dt>
                            <dd className="text-gray-8">
                              <ul>
                                <li>・ 압류내역 : {item?.content}</li>
                                <li>・ 촉탁기관 : {item?.agency}</li>
                                {item.agencyPhoneNumber && <li>・ 전화번호 : {item?.agencyPhoneNumber}</li>}
                              </ul>
                            </dd>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-gray-6">{history.title} 없음</div>
                    )}
                  </div>
                </div>
              )}

              {history.key === 'MORTGAGE' && !!history.values && (
                <div className="py-6">
                  <p className="text-lg font-semibold pb-3" id={history.key}>
                    {history.title}
                  </p>
                  <div className="p-4 bg-gray-1 rounded-lg">
                    {history.values && history.values.length > 0 ? (
                      history.values.map((item, index) => (
                        <div key={index} className={`text-base ${index !== 0 ? 'border-t border-gray-3 mt-5' : ''}`}>
                          <div>
                            <dt className={`font-semibold ${index === 0 ? '' : 'mt-5'}`}>{item.occurDate}</dt>
                            <dd className="text-gray-8">
                              <ul>
                                <li>・ 기관 : {item?.mortgageName}</li>
                                <li>・ 채무자 : (차주){item?.debtorName}</li>
                                <li>・ 채권가액 : {item?.amount}원</li>
                              </ul>
                            </dd>
                          </div>
                          <div
                            className={`${
                              index + 1 === history.values?.length
                            } text-gray-7 text-[12px] leading-[16px] pt-4`}
                          >
                            * 차량 대금 입금 전, ‘조회 당일 기준’ 남아있는 완납금을 확인하시고 차량 대금보다 완납 금액이
                            높게 남아있을 경우 차액 금액은 판매자가 처리할 수 있어야 합니다.
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-gray-6">{history.title} 없음</div>
                    )}
                  </div>
                </div>
              )}

              {history.key === 'TUNING' && (
                <div className="py-6">
                  <p className="text-lg font-semibold pb-3" id={history.key}>
                    {history.title}
                  </p>
                  <div className="p-4 bg-gray-1 rounded-lg">
                    {history.values && history.values.length > 0 ? (
                      history.values.map((item, index) => (
                        <div key={index} className={`text-base ${index !== 0 ? 'border-t border-gray-3 mt-5' : ''}`}>
                          <div>
                            <dt className={`font-semibold ${index === 0 ? '' : 'mt-5'}`}>{item.date}</dt>
                            <dd className="text-gray-8">
                              <ul>
                                <li>・ 구조변경 : {item.before}</li>
                                <li>・ 구조변경 후 내역 : {item.after}</li>
                              </ul>
                            </dd>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-gray-6">{history.title} 없음</div>
                    )}
                  </div>
                </div>
              )}

              {(history.key === 'INSPECTION' || history.key === 'TRADING') && (
                <div className="py-6">
                  <p className="text-lg font-semibold pb-3" id={history.key}>
                    {history.title}
                  </p>
                  <div className="p-4 bg-gray-1 rounded-lg">
                    {history.values && history.values.length > 0 ? (
                      history.values.map((item, index) => (
                        <div key={index} className={`text-base ${index !== 0 ? 'border-t border-gray-3 mt-5' : ''}`}>
                          <div>
                            <dt className={`font-semibold ${index === 0 ? '' : 'mt-5'}`}>{item.date}</dt>
                            <dd className="pt-3">{item.content}</dd>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-gray-6">{history.title} 없음</div>
                    )}
                  </div>
                </div>
              )}
            </dl>
          </div>
        </div>
      ))}
    </div>
  );
}
