import { AxiosError } from 'axios';
import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { patchProductsInfo } from '@/api/products/updateProducts';
import RoundBadge from '@/components/Common/Badge/RoundBadge';
import DualFooterButton from '@/components/Common/Button/DualFooterButton';
import SalesTypeSelectButton from '@/components/Common/Button/SalesTypeSelectButton';
import CarPriceTrendInfo from '@/components/Common/CarPriceTrendInfo';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import DownToUpDetailPopup from '@/components/Common/Popup/DownToUpDetailPopup';
import MenuHeader from '@/components/Header/MenuHeader';
import SimilarProductList from '@/components/Products/SimilarProductList';
import { PRODUCTS_CAN_SALES_MAX_THREE } from '@/const/errorCode';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { PRODUCT_TYPE_DIRECT, PRODUCT_TYPE_SPEED, SALESTYPE } from '@/const/products';
import { SELL_CAR_DEFAULT_TITLE } from '@/const/title';
import { PRODUCT_SALE_URL } from '@/const/url';
import { useToastContext } from '@/contexts/Common/ToastContext';
import useBlockNavigation from '@/hooks/useBlockNavigation';
import useFetchProductData from '@/hooks/useFetchProductData';
import { productsFormAtom, useResetProductsForm } from '@/store/products';
import { getFormatDateOnlyNum } from '@/utils/common';

type SalesTypeKey = keyof typeof SALESTYPE;

const PriceTrendResultPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { showToast } = useToastContext();

  const [productFormData] = useAtom(productsFormAtom);
  const setProductFormData = useSetAtom(productsFormAtom);
  const resetProductsForm = useResetProductsForm();

  const [isShowExitConfirmPopup, setIsShowExitConfirmPopup] = useState(false);
  const [isShowSelectPopup, setIsShowSelectPopup] = useState(false);

  const productSearchParams = useFetchProductData(id, true);

  useBlockNavigation(setIsShowExitConfirmPopup);

  useEffect(() => {
    if (productFormData.type?.code === '') {
      setIsShowSelectPopup(true);
    }
  }, [productFormData.type?.code]);

  const handleClickExitBtn = () => {
    setIsShowExitConfirmPopup(false);
    navigate(PRODUCT_SALE_URL, { replace: true });
    resetProductsForm();
  };

  const handleCloseExitPopup = () => {
    setIsShowExitConfirmPopup(false);
  };

  const handleClickAdditionalInfo = () => {
    navigate(`/products/sales/additional-info/${id}`, { replace: true });
  };

  const handleClickPrev = () => {
    navigate(`/products/sales/axis/${id}`, { replace: true });
  };

  const handleClickSaleHelp = () => {
    navigate('/one-stop-service');
  };

  const updateProductsInfoMutation = useMutation(
    (request: { productId: number; type: string }) => patchProductsInfo(request),
    {
      onSuccess: (response) => {
        setProductFormData(response.data);
      },
      onError: (error: AxiosError) => {
        if (error.code === PRODUCTS_CAN_SALES_MAX_THREE) {
          showToast(error.message, 'error', 'bottom');
        } else {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        }
      },
    },
  );

  const handleClickSalesType = (type: string) => {
    setIsShowSelectPopup(false);
    updateProductsInfoMutation.mutate({ productId: Number(id), type });
  };

  return (
    <>
      <MenuHeader title={SALESTYPE[productFormData.type?.code as SalesTypeKey] || SELL_CAR_DEFAULT_TITLE} />
      <div className="flex flex-col w-full p-4 pb-24 pt-[60px]">
        <div className="pb-24 pt-6">
          <div className="flex justify-between">
            <h3 className="text-2xl font-bold break-keep">차량 시세를 확인해주세요.</h3>
            <RoundBadge text="5/9" />
          </div>
          <div className="rounded-lg bg-blue-0 p-4 mt-6">
            <p className="text-lg font-bold mb-2 text-gray-8">
              {getFormatDateOnlyNum(productFormData?.firstRegistrationDate, ['year'])}년{' '}
              {productFormData?.manufacturerCategories?.name} {productFormData?.model?.name} {productFormData?.tons}톤{' '}
              {productFormData?.axis?.desc} {productFormData?.loaded?.desc}
            </p>
            <span className="text-gray-8 text-sm">해당 차종의 평균 시세 정보를 불러왔어요.</span>
          </div>
          <div className="mt-3">
            <p className="text-xs text-gray-6">
              * 차량의 주행거리, 차량의 상태, 옵션등에 따라 시세가 변동될 수 있습니다.
            </p>
            <CarPriceTrendInfo
              priceSearchParams={productSearchParams}
              showPriceComparison={false}
              isSearchPage={false}
              apiType="private"
            />
            <SimilarProductList similarSearchParams={productSearchParams} />
          </div>

          <DualFooterButton
            leftButtonText="이전"
            onClickLeftButton={handleClickPrev}
            rightButtonText="추가 정보 입력하기"
            onClickRightButton={handleClickAdditionalInfo}
          />
        </div>
      </div>
      <BasicPopup
        isShow={isShowExitConfirmPopup}
        title="정말 나가시겠어요?"
        textContent={'작성 중인 내용은 임시저장되며<br/>언제든지 재등록이 가능합니다.'}
        textLeftBtn="취소"
        onClickLeftBtn={handleCloseExitPopup}
        textRightBtn="나가기"
        onClickRightBtn={handleClickExitBtn}
      />

      <DownToUpDetailPopup
        isShow={isShowSelectPopup}
        onClosePopup={() => setIsShowSelectPopup(false)}
        title={'판매 방식 선택'}
        isShowXMark={false}
      >
        <SalesTypeSelectButton
          onClickLeft={() => handleClickSalesType(PRODUCT_TYPE_DIRECT)}
          onClickRight={() => handleClickSalesType(PRODUCT_TYPE_SPEED)}
        ></SalesTypeSelectButton>
        <div
          className="my-[30px] text-center font-medium text-[14px] text-gray-7 underline underline-offset-2"
          onClick={handleClickSaleHelp}
        >
          차량 판매에 어려움이 있으신가요?
        </div>
      </DownToUpDetailPopup>
    </>
  );
};

export default PriceTrendResultPage;
