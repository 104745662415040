import React from 'react';

import { asYYYYMMDD, formatNumber, formatShortYear, getFormatDate } from '@/utils/common';

type ProductDetailProps = {
  product?: ProductDetailResponse;
};

interface Data {
  title?: string;
  value?: string | number | undefined | null;
  values?: EnumPresenter[] | undefined;
  unit?: string | undefined;
  etc?: string | undefined;
}

export default function ProductInfo({ product, ...props }: ProductDetailProps) {
  const productInfo: Data[] = [
    { title: '차량 번호', value: product?.truckNumber },
    { title: '제조사/모델', value: `${product?.manufacturerCategories.name}/${product?.model.name}` },
    { title: '연식', value: getFormatDate(product?.firstRegistrationDate, ['year', 'month'], true) },
    { title: '형식', value: formatShortYear(product?.year), unit: '년형' },
    { title: '차고지', value: product?.garage?.desc },
    { title: '마력수', value: product?.power, unit: 'ps' },
    { title: '변속기', value: product?.transmission?.desc },
    { title: '톤수', value: product?.tons, unit: 't' },
    { title: '적재함 종류', value: product?.loaded?.desc },
    { title: '주행거리', value: formatNumber(String(product?.distance)), unit: 'km' },
    { title: '가변축', value: product?.axis?.desc },
    { title: '연료', value: product?.fuel?.desc },
    { title: '차량 색상', value: product?.color?.desc },
  ];

  const productLoadedInnerInfo: Data[] = [
    { title: '길이', value: product?.loadedInnerLength, unit: 'm' },
    { title: '너비', value: product?.loadedInnerArea, unit: 'm' },
    { title: '높이', value: product?.loadedInnerHeight, unit: 'm' },
    { title: '파렛트', value: product?.palletCount, unit: '개' },
  ];

  const productAddedInfo: Data[] = [
    {
      title: '검사 유효기간',
      value:
        product?.inspectionInvalidStartDate === null || product?.inspectionInvalidEndDate === null
          ? ''
          : `${asYYYYMMDD(product?.inspectionInvalidStartDate)} ~ ${asYYYYMMDD(product?.inspectionInvalidEndDate)}`,
    },
    { title: '사고유무', value: product?.accidentsHistory?.accidentContents ? '사고' : '무사고' },
    { title: '사고이력', value: product?.accidentsHistory?.accidentContents },
    { title: '운송 물품', value: product?.transportGoods },
    {
      title: '운송 주요 구간',
      value:
        product?.transportStartLocate === null || product?.transportEndLocate === null
          ? ''
          : `${product?.transportStartLocate?.desc} ~ ${product?.transportEndLocate?.desc}`,
    },
    { title: '타이어 상태', value: product?.tireStatus?.desc },
  ];

  const productCarOptions: Data[] = [
    {
      title: '일반 옵션',
      values: product?.carOption?.normalOption?.option,
      etc: product?.carOption?.normalOption?.etc,
    },
    {
      title: '추가 옵션',
      values: product?.carOption?.additionalOption?.option,
      etc: product?.carOption?.additionalOption?.etc,
    },
    {
      title: '브레이크 옵션',
      values: product?.carOption?.breakOption?.option,
      etc: product?.carOption?.breakOption?.etc,
    },
  ];
  const isNoneMaintenance = () => {
    if (product && product.maintenance) {
      const { maintenanceData, etc } = product.maintenance;
      return (maintenanceData?.length === 0 && etc === '') || false;
    }
  };

  return (
    <div className="py-2 my-2 text-gray-8">
      <div className="px-4 pt-3 pb-1">
        {productInfo.map(
          (item, index) =>
            item.value && (
              <dl key={index} className="flex justify-between pb-4">
                <dt className="text-base text-gray-7">{item.title}</dt>
                <dd>
                  {item.value && item.value}
                  {item.unit}
                </dd>
              </dl>
            ),
        )}
      </div>
      <div className="border-t border-gray-4 mx-4"></div>
      <div className="px-4 pt-3 pb-1">
        <h1 className="font-semibold text-lg">제원정보</h1>
        <p className="text-sm text-gray-7 py-1 pb-4">적재함의 내측 사이즈 정보 입니다.</p>
        {productLoadedInnerInfo.map(
          (item, index) =>
            !!item.value && (
              <dl key={index} className="flex justify-between mb-3">
                <dt className="text-base text-gray-7">{item.title}</dt>
                <dd>
                  {item.value}
                  {item.unit}
                </dd>
              </dl>
            ),
        )}
      </div>
      <div className="border-t border-gray-4 mx-4"></div>
      <div className="px-4 pt-3 pb-1">
        <h1 className="font-semibold text-lg pb-4">차량 추가 정보</h1>
        {productAddedInfo.map(
          (item, index) =>
            item.value && (
              <dl key={index} className="flex justify-between pb-4">
                <dt className="text-base text-gray-7 w-[100px]">{item.title}</dt>
                <dd className="flex-1 text-right break-keep">{item.value && item.value}</dd>
                {item.values && item.values.map((value, index) => value !== null && <dd key={index}>{value.desc}</dd>)}
              </dl>
            ),
        )}
      </div>
      <div className="px-4 py-6 border-t-8 border-gray-1 pb-6 flex flex-col gap-4">
        <h1 className="font-semibold text-lg">차량 옵션</h1>
        {productCarOptions &&
          productCarOptions.map((item, index) => (
            <div key={index}>
              {item?.values && (
                <div className="bg-gray-1 p-4 rounded w-full">
                  <p className="text-base font-semibold mb-2">{item.title}</p>
                  <div className={`grid grid-cols-2 ${!!item.etc ? 'mb-2' : ''}`}>
                    {item.values.length > 0 &&
                      item.values.map((value, index) => (
                        <span key={index} className="">
                          ・{value.desc}
                        </span>
                      ))}
                  </div>
                  {!!item.etc && (
                    <>
                      <div
                        className={`${
                          item.values.length === 0 ? '' : 'border-t border-gray-4 pt-3'
                        } flex items-center truncate`}
                      >
                        <span>・{item.etc}</span>
                      </div>
                    </>
                  )}
                  {item.values.length === 0 && !item.etc && <span className="text-base text-gray-6">없음</span>}
                </div>
              )}
            </div>
          ))}
      </div>
      <div className="px-4 border-t-8 border-gray-1 py-6">
        <h1 className="font-semibold text-lg">차량 정비이력</h1>
        <p className="text-sm text-gray-7 py-1 pb-4">
          최근 1년 이내 주요 부품 수리내역 입니다. <br></br>자세한 사항은 판매자에게 문의해보세요.
        </p>
        <>
          <div className="bg-gray-1 p-4 rounded">
            {isNoneMaintenance() ? (
              <span className="text-gray-6">차량 정비 이력 없음</span>
            ) : (
              <div className={`grid grid-cols-2 ${!!product?.maintenance?.etc ? 'mb-2' : ''}`}>
                {product?.maintenance?.maintenanceData?.length !== 0 &&
                  product?.maintenance?.maintenanceData?.map((item, index) => <span key={index}>・{item.desc}</span>)}
              </div>
            )}
            {!!product?.maintenance?.etc && (
              <>
                <div
                  className={`${
                    product?.maintenance?.maintenanceData?.length === 0 ? '' : 'border-t border-gray-4 pt-3'
                  } flex items-center`}
                >
                  <div>・{product?.maintenance?.etc}</div>
                </div>
              </>
            )}
          </div>
        </>
      </div>
      {product?.detailContent && (
        <>
          <div className="px-4 py-6 border-t-8 border-gray-1">
            <h1 className="font-semibold text-lg pb-4">설명</h1>
            <div className="bg-gray-1 p-4 rounded text-base">
              {product?.detailContent.split('\n').map((line, index) => {
                return (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
