import { AxiosError } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { patchProductsAfterPriceSearch } from '@/api/public';
import CarPriceTrendInfo from '@/components/Common/CarPriceTrendInfo';
import ButtonFooter from '@/components/Footer/ButtonFooter';
import SimilarProductList from '@/components/Products/SimilarProductList';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { isLengthOnlyLoadedType } from '@/utils/Products/productUtils';

interface ProductProps {
  productOriginData?: ProductDetailResponse | undefined;
  productSearchParams: ProductSearchParams;
}

const PriceTrendResult: React.FC<ProductProps> = ({ productSearchParams, productOriginData }) => {
  const navigate = useNavigate();
  const { showToast } = useToastContext();

  const handleClick = () => {
    if (
      productSearchParams?.productId &&
      productSearchParams?.status?.code !== 'SALE' &&
      productSearchParams?.status?.code !== 'PAUSE'
    ) {
      patchAfterPriceSearchMutation.mutate(String(productSearchParams?.productId));
    } else {
      navigate('/products/sales');
    }
  };

  const patchAfterPriceSearchMutation = useMutation((request: string) => patchProductsAfterPriceSearch(request), {
    onSuccess: (response) => {
      if (isLengthOnlyLoadedType(response) || response?.loaded === '' || response?.loaded === null) {
        navigate(`/products/sales/price-trend/${response.id}`);
      } else {
        navigate(`/products/sales/loaded/${response.id}`);
      }
    },
    onError: (error: AxiosError) => {
      if (error.code === 'PRODUCTS_CAN_SALES_MAX_THREE') {
        showToast(error.message, 'error', 'bottom');
      } else {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      }
    },
  });

  useEffect(() => {
    let scrollTimeout: NodeJS.Timeout;

    const handleScroll = () => {
      setIsScrolling(true);
      clearTimeout(scrollTimeout);

      scrollTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 500);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, []);

  const [isScrolling, setIsScrolling] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [bubblePosition, setBubblePosition] = useState(0);

  useEffect(() => {
    if (buttonRef.current) {
      const buttonWidth = buttonRef.current.clientWidth;
      setBubblePosition(buttonWidth / 4);
    }
  }, []);

  return (
    <>
      <div className="rounded-lg bg-blue-0 p-4 mt-6">
        <p className="text-lg font-bold mb-2">
          {productOriginData ? productOriginData.year : productSearchParams.year}년{' '}
          {productOriginData
            ? productOriginData.manufacturerCategories?.name
            : productSearchParams.manufacturerCategories?.name}{' '}
          {productSearchParams?.model?.name} {productSearchParams?.tons}톤 {productSearchParams?.axis?.desc}{' '}
          {productSearchParams?.loaded?.desc}
        </p>
        <span className="text-gray-8 text-sm">해당 차종의 평균 시세 정보를 불러왔어요.</span>
      </div>
      <div className="mt-3">
        <p className="text-xs text-gray-6">* 차량의 주행거리, 차량의 상태, 옵션등에 따라 시세가 변동될 수 있습니다.</p>
        <CarPriceTrendInfo
          priceSearchParams={productSearchParams}
          showPriceComparison={false}
          isSearchPage={true}
          apiType="private"
        ></CarPriceTrendInfo>
        <SimilarProductList similarSearchParams={productSearchParams}></SimilarProductList>
      </div>

      <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-3 pt-4 pb-12 bottom-0 left-0 right-0 h-18 max-w-[720px] min-w-[280px] bg-white shadow-[0_-2px_4px_rgba(0,0,0,0.05)] z-40">
        <ButtonFooter title="내 차 판매하러가기" path="/products/sales" onClick={handleClick}></ButtonFooter>
        <div className="absolute bottom-4 text-primary">입력한 정보로 빠르게 차량을 판매해보세요.</div>
      </div>
    </>
  );
};

export default PriceTrendResult;
