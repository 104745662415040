import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BasicButton from './Button/BasicButton';
import TextInputLabelOff from './Input/TextInputLabelOff';
import apiManager from '@/api/AxiosInstance';
import LoadingSpinner from '@/components/Common/LoadingSpinner';
import { colors } from '@/const/colors';
import { useToastContext } from '@/contexts/Common/ToastContext';

interface LicensePlateInputProps {
  updatePage: (pageNum: number) => void;
  ownerInfo: OwnerInfo;
  setOwnerInfo: React.Dispatch<React.SetStateAction<OwnerInfo>>;
}
interface ErrorInfoProps {
  errorInfo: ErrorInfo | undefined;
  setErrorInfo: React.Dispatch<React.SetStateAction<ErrorInfo | undefined>>;
}

interface OriginDataProps {
  productOriginData: ProductDetailResponse | undefined;
  setProductOriginData: React.Dispatch<React.SetStateAction<ProductDetailResponse | undefined>>;
}

const NameInputComponent: React.FC<LicensePlateInputProps & ErrorInfoProps & OriginDataProps> = ({
  updatePage,
  ownerInfo,
  setOwnerInfo,
  errorInfo,
  setErrorInfo,
  productOriginData,
  setProductOriginData,
}) => {
  const { showToast } = useToastContext();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isLoading) return;
    const { value } = e.currentTarget;
    if (e.key === 'Enter') {
      if (!value) {
        setErrorInfo({
          ...errorInfo,
          ownerNameError: true,
          ownerNameErrorMessage: '소유자 정보가 맞지 않습니다. 다시 입력해주세요.',
          licenseNumberError: false,
          licenseNumberErrorMessage: '',
        });
        return;
      } else {
        onClickGetData(value);
      }
    }
  };

  const onClickGetData = (value: string) => {
    if (isLoading) return;
    if (!value) {
      setErrorInfo({
        ...errorInfo,
        ownerNameError: true,
        ownerNameErrorMessage: '소유자 정보가 맞지 않습니다. 다시 입력해주세요.',
        licenseNumberError: false,
        licenseNumberErrorMessage: '',
      });
      return;
    }
    setIsLoading(true);

    const queryParams = new URLSearchParams();
    queryParams.append('carNumber', ownerInfo.licenseNumber);
    if (ownerInfo.name) {
      queryParams.append('ownerName', ownerInfo.name);
    }
    apiManager
      .get('/api/v1/prices/truck-info?' + queryParams.toString())
      .then((response) => {
        const responseData: ProductDetailResponse = response.data;

        setIsLoading(false);
        setProductOriginData(responseData);
        navigate('/price-trend/form', { state: { ownerInfo, productOriginData: responseData } });
      })
      .catch((error) => {
        setIsLoading(false);

        const licenseNumberErrorCodes = [
          'NOT_EXIST_RESPONSE_BY_DATA_HUB',
          'MISSING_CAR_NUMBER',
          'DATA_HUB_CAR_INFO_FOUND_FAIL',
          'NOT_MATCH_CAR_INFORMATION',
        ];

        const ownerNameErrorCodes = ['NOT_MATCH_OWNER_NAME', 'MISSING_OWNER_NAME', 'PRODUCTS_CAN_SALES_MAX_THREE'];

        if (licenseNumberErrorCodes.includes(error.code)) {
          setErrorInfo({
            ...errorInfo,
            licenseNumberError: true,
            licenseNumberErrorMessage: `${error.message}`,
            ownerNameError: false,
            ownerNameErrorMessage: '',
          });
          updatePage(1);
          showToast(`${error?.message}`, 'error', 'bottom');
        } else if (ownerNameErrorCodes.includes(error.code)) {
          setErrorInfo({
            ...errorInfo,
            licenseNumberError: false,
            licenseNumberErrorMessage: '',
            ownerNameError: true,
            ownerNameErrorMessage: `${error.message}`,
          });
        }
      });
  };

  const onChangeOwnerName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setErrorInfo({
      ...errorInfo,
      ownerNameError: false,
      ownerNameErrorMessage: '',
      licenseNumberError: false,
      licenseNumberErrorMessage: '',
    });
    setOwnerInfo({ ...ownerInfo, name: value });
  };

  return (
    <>
      <div className="flex flex-col justify-between w-full">
        {isLoading && (
          <LoadingSpinner text="데이터를 조회중입니다. 잠시만 기다려주세요..." isTextChanging></LoadingSpinner>
        )}
        <div className="relative w-full flex justify-between align-center">
          <TextInputLabelOff
            name="ownerName"
            placeholder="예) 주식회사 OOO, 홍길동"
            value={ownerInfo.name}
            height={50}
            fontSize={18}
            onChange={onChangeOwnerName}
            onKeyDown={handleKeyPress}
            error={errorInfo?.ownerNameError}
            errorMsg={errorInfo?.ownerNameErrorMessage}
            type="text"
            paddingRight={82}
          ></TextInputLabelOff>
          <div className="absolute right-0 bottom-2">
            <BasicButton
              name="조회"
              bgColor={colors.primary}
              borderColor={colors.primary}
              textColor={colors.gray[0]}
              fontSize={14}
              width={72}
              height={40}
              borderRadius={8}
              fontWeight="semibold"
              onClick={() => onClickGetData(ownerInfo.name)}
              isDisabled={!ownerInfo.name}
            ></BasicButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default NameInputComponent;
