import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';

import { getNotice } from '@/api/public';
import MenuHeader from '@/components/Header/MenuHeader';
import { ChevronRightIcon, EmptySpeakerIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import useFetchNoticeListQuery from '@/hooks/query/useFetchNoticeListQuery';
import { asYYYYMMDD } from '@/utils/common';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface Notice {
  id: number;
  title: string;
  contents: string;
  createdDate: string;
  modifiedDate: string;
}

const NoticeList = () => {
  const navigate = useNavigate();

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  const getNoticeList = async (page: number) => {
    try {
      const response = await getNotice(page);
      const data: Notice[] = response;
      return data;
    } catch {
      throw new Error('Error');
    }
  };

  const { noticeList, isLoading, isFetching, isError, hasNextPage, fetchNextPage, isFetchingNextPage, refetch } =
    useFetchNoticeListQuery({
      rowsPerPage: 10,
      queryFn: ({ queryKey, pageParam = 1 }) => {
        return getNoticeList(pageParam);
      },
    });

  const handleClickNotice = (notice: Notice) => {
    navigate(`/notice/${notice.id}`);
  };

  if (!isLoading && !isFetching && noticeList && noticeList.length === 0) {
    return (
      <div className="w-full">
        <MenuHeader title="공지사항" />
        <div className="pt-[60px] px-4 w-full h-full px-4" style={{ height: `calc(100vh - 180px)` }}>
          <div className="flex flex-col justify-center items-center w-full h-full">
            <EmptySpeakerIcon width={120} height={120} color={colors.gray[4]} />
            <span className="text-gray-7 text-[18px] mt-4">공지사항 내역이 없어요.</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full">
      <MenuHeader title="공지사항" />
      <div className="pt-[60px] px-4">
        <ul>
          {!isLoading &&
            !isFetching &&
            noticeList &&
            noticeList.map((notice: Notice, index: number) => (
              <li
                className={`flex gap-2 justify-between py-[18px] cursor-pointer ${
                  index !== 0 ? 'border-t border-gray-4' : ''
                }`}
                key={notice.id}
                onClick={() => handleClickNotice(notice)}
              >
                <div>
                  <div className="font-semibold text-gray-8 leading-[19px]">{notice.title}</div>
                  <div className="text-gray-6 text-[14px] leading-[17px] mt-2">
                    {asYYYYMMDD(notice.createdDate, true)}
                  </div>
                </div>

                <div className="flex items-center justify-center">
                  <ChevronRightIcon color={colors.gray[5]} />
                </div>
              </li>
            ))}
        </ul>
        <div
          ref={ref}
          className={`h-40 flex flex-col justify-center items-center pb-[76px] ${hasNextPage ? '' : 'hidden'}`}
        >
          <MoreVertIcon sx={{ fontSize: 80, color: colors.gray[2] }}></MoreVertIcon>
        </div>
      </div>
    </div>
  );
};

export default NoticeList;
