import React from 'react';

import { CarPaymentColorIcon, CautionColorIcon, NameTransferColorIcon } from '@/components/Icon';

const TransferGuideView = () => {
  return (
    <div className="bg-gray-0">
      <div className="flex flex-col items-center bg-gradient-to-b from-[#414141] to-[#ffffff] py-10">
        <p className="text-gray-0 text-[20px] font-bold text-center mb-[30px]">
          구매 / 판매 차량은 결정됐는데
          <br />
          다음은 어떤 절차를 거쳐야할지
          <br />
          막막하시나요?
        </p>

        <div className="w-full flex justify-center items-center max-w-[155px] max-h[156px] mb-[30px]">
          <img
            src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/transfer_agency_service.png"
            alt="구매 동행 서비스 트럭 이미지"
            className="w-full"
          />
        </div>
        <p className="text-gray-8 text-[24px] font-extrabold text-center">
          <span className="text-blue-8">서류 이전 대행 서비스</span>로
          <br />
          간편하게 해결하세요
        </p>
      </div>

      <div className="py-11 flex flex-col justify-center items-center">
        <p className="text-[20px] text-gray-8 text-center font-bold pb-8">서류 이전 대행 서비스란?</p>
        <p className="text-gray-8 text-center pb-5 text-pretty mx-7 break-keep text-[16px]">
          <span className="font-bold">
            차량 명의 이전에 필요한 <br />
            준비 서류, 차량 대금 처리, 거래 시 유의 사항{' '}
          </span>
          등<br /> 화물차 직거래 매칭 시 고객님들의
          <br />
          안전한 거래를 위해 도움을 드리는
          <br />
          서비스입니다.
        </p>
        <div className="flex flex-wrap justify-center gap-3 mt-[14px]">
          <div className="flex flex-col items-center justify-center text-center rounded-[26px] w-[90px] h-[90px] text-gray-8 bg-gray-1 font-semibold text-[14px] leading-[18px]">
            <NameTransferColorIcon width={30} height={30}></NameTransferColorIcon>
            <div>
              명의이전
              <br />
              준비서류
            </div>
          </div>
          <div className="flex flex-col items-center justify-center text-center rounded-[26px] w-[90px] h-[90px] text-gray-8 bg-gray-1 font-semibold text-[14px] leading-[18px]">
            <CarPaymentColorIcon width={36} height={36}></CarPaymentColorIcon>
            <div>
              차량대금
              <br />
              처리
            </div>
          </div>
          <div className="flex flex-col items-center justify-center text-center rounded-[26px] w-[90px] h-[90px] text-gray-8 bg-gray-1 font-semibold text-[14px] leading-[18px]">
            <CautionColorIcon width={33} height={33}></CautionColorIcon>
            <div>
              거래시
              <br />
              유의사항
            </div>
          </div>
        </div>

        <div className="text-gray-6 text-[12px] mt-[46px]">※ 해당 서비스는 유료 서비스입니다.</div>
      </div>

      <div className="bg-gray-1 py-[46px] px-4 text-gray-8 text-center text-pretty break-keep">
        명의 이전에 필요한 서류 및 절차는
        <br />
        법인명의 영업용번호판,
        <br />
        개인명의 영업용번호판에 따라 상이하므로 <br className="xxs:hidden" />
        서비스 신청 후 별도의 안내를 드릴 예정입니다.
      </div>
    </div>
  );
};

export default TransferGuideView;
